import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import EXAM_SUBJECTS from 'constants/exam-subjects';

const { Panel } = Collapse;

const getStudentScore = (student, app) => {
  let field = EXAM_SUBJECTS.scores[app.subject_name];
  if (_.get(student, field)) {
    return _.get(student, field);
  }

  return 0;
};

const Exam = ({ student }) => {
  // let { studentId } = useParams();
  const [examApplications, setExamApplications] = useState([]);
  const subjects = useSelector((state) => state.subjects || []);

  const fetchApplication = async () => {
    const result = await callApi({
      url: `/students/${student.citizen_id}/exam-applications`,
    });
    result.forEach((app) => (app.index = EXAM_SUBJECTS.index[app.subject_name]));
    setExamApplications(result);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchApplication();
    }
  }, [student]);

  if (!examApplications) {
    return <div></div>;
  }

  const sortedApps = _.orderBy(examApplications, 'index');
  const appsGroupByRecheckable = _.groupBy(sortedApps, 'recheckable');
  // const repliesByRound = _.groupBy(replies, 'type');

  // console.log(repliesByRound);
  const getExamAppDetail = async (app) => {
    if (!app.detail) {
      const result = await callApi({
        url: `/students/${student.citizen_id}/exam-applications/${app._id}`,
      });
      // app.detail = result;
      const updatedApps = examApplications.map((_app) => {
        if (app.subject_name === _app.subject_name) {
          _app.detail = result;
        }
        return _app;
      });
      setExamApplications(updatedApps);
    }
  };

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>
      {_.map(appsGroupByRecheckable, (apps, day) => {
        return (
          <div>
            <div>
              {apps.map((app, i) => {
                return (
                  <Collapse onChange={(e) => getExamAppDetail(app)}>
                    <Panel
                      header={
                        <div>
                          <div style={{ float: 'right' }}>
                            {/* {app.subject_name === 'GAT' &&
                              `GAT1:${`${
                                _.get(student, `${EXAM_SUBJECTS.scores[app.subject_name]}1`) || 0
                              }`}, GAT2:${`${_.get(student, `${EXAM_SUBJECTS.scores[app.subject_name]}2`) || 0}`}   `} */}
                            {getStudentScore(student, app)}/ {EXAM_SUBJECTS.maxScore[app.subject_name]}
                          </div>
                          <div>{EXAM_SUBJECTS.labels_66[app.subject_name]}</div>
                        </div>
                      }
                      key={i}
                    >
                      <ul className="r-history">
                        <li>
                          <div key={app._id} className="xm-box">
                            <ul>
                              {app.detail &&
                                app.detail.keys &&
                                app.detail.keys.map((url, index) => {
                                  return (
                                    <li key={index}>
                                      <div
                                        className="key"
                                        style={{ width: '49%', marginRight: '2%', display: 'inline-block' }}
                                      >
                                        <img src={_.get(app, `detail.sheet_path_${index + 1}`)}></img>
                                      </div>
                                      <div className="student-answer" style={{ width: '49%', display: 'inline-block' }}>
                                        <img src={url}></img>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <br></br>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Exam;
