import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const { TabPane } = Tabs;

import Profile from './Profile';
import OfferReply from './OfferReply';
import Exam from './Detail/Exam';
import ExamRegister from './Detail/ExamRegister';
import ExamCompare from './Detail/ExamCompare';
import Admission from './Detail/Admission';
import AdmissionResult from './Detail/Round3Result';
import Logs from './Detail/Logs';

const StudentDetail = () => {
  let { studentId } = useParams();
  const dispatch = useDispatch();
  const [student, setStudent] = useState();
  const subjects = useSelector((state) => state.subjects || []);

  const fetchStudentDetail = async () => {
    const result = await callApi({
      url: `/students/${studentId}`,
    });
    setStudent(result);
  };

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });

      // setStudents(students || []);
    } catch (error) {
      console.log('errror', error);
      // setStudents([]);
    }
  };

  useEffect(() => {
    fetchStudentDetail();
    fetchSubjects();
  }, []);

  return (
    <div>
      <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32 }}>
        <TabPane type="card" tab="ข้อมูลนักเรียน" key="1">
          <Profile fetchStudent={fetchStudentDetail} student={student}></Profile>
        </TabPane>
        <TabPane type="card" tab="ข้อมูลการสมัครสอบ" key="2">
          <ExamRegister student={student}></ExamRegister>
        </TabPane>
        <TabPane type="card" tab="ข้อมูลการใช้สิทธิ์" key="3">
          <OfferReply student={student}></OfferReply>
        </TabPane>

        <TabPane type="card" tab="ผลการคัดเลือกรอบ 3" key="4">
          <AdmissionResult student={student}></AdmissionResult>
        </TabPane>
        <TabPane type="card" tab="การสมัครรอบ 3" key="5">
          <Admission student={student}></Admission>
        </TabPane>
        <TabPane type="card" tab="ข้อมูลการสอบ" key="6">
          <Exam student={student}></Exam>
        </TabPane>
        <TabPane type="card" tab="ตรวจผลสอบ" key="7">
          <ExamCompare student={student}></ExamCompare>
        </TabPane>
        <TabPane type="card" tab="ประวัติการใช้งาน" key="8">
          <Logs student={student}></Logs>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default StudentDetail;
