import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import DownloadModal from './DownloadModal';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';
import UpdateStatusModal from './UpdateStatusModal';
import UpdateCitizenModal from './UpdateCitizenModal';
import AddModal from './AddModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const schools = useSelector((state) => {
    return state.schools || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);
  const universities = useSelector((state) => state.universities || []);
  const [university_id, setUniverysityId] = useState('');
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [enrollments, setStudents] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [programProjects, setProgramProjects] = useState([]);
  const [selectedEnrollmentOffer, setSelectedEnrollmentOffer] = useState();
  const [updateStatusModalVisible, setUpdateStatusModalVisible] = useState(false);
  const [updateCitizenModalVisible, setUpdateCitizenModalVisible] = useState(false);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    program: 'all',
    major: 'all',
    project: 'all',
    type: 'all',
    status: 'all',
  });

  const fetchUniversities = async () => {
    if (universities.length > 0) {
      return;
    }
    try {
      const result = await callApi({
        url: '/configs/universities',
      });
      dispatch({ type: 'UPDATE_UNIVERSITIES', data: result });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchPrograms = async (universityId) => {
    // if (universities.length > 0) {
    //   return;
    // }
    try {
      const userUniversityId = me.role === 'staff' ? me.university_id : '999';
      const query = {
        university: universityId || userUniversityId,
      };
      const result = await callApi({
        url: '/configs/programs',
        query,
      });
      dispatch({
        type: 'UPDATE_COURSES',
        data: result,
        university: universityId || userUniversityId,
      });

      // setPrograms(programs || []);
    } catch (error) {
      console.log('errror', error);
      // setPrograms([]);
    }
  };
  // const [creditCount, setCreditCount] = useState()
  const fetchEnrollments = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: enrollments } = await callApi({
        url: '/enrollment-offers',
        query,
      });
      setStudents(enrollments || []);
      setStudentCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setStudents([]);
      setLoading(false);
    }
  };
  const fetchProgramProjects = async (programId) => {
    try {
      const { data: _programProjects } = await callApi({
        url: `/program-projects?program=${programId || filterQuery.program}`,
      });
      setProgramProjects(orderBy(_programProjects, 'project_id'));
    } catch (error) {
      console.log('errror', error);
      setProgramProjects([]);
    }
  };

  // useEffect(() => {
  //   if (filterQuery.university) {
  //     fetchEnrollments(1, { university: filterQuery.university || '999' });
  //   }
  // }, [filterQuery]);

  useEffect(() => {
    if (me) {
      let university = me.university_id || '999';
      if (me.role === 'super') {
        university = 'all';
      }

      setFilterQuery({ ...filterQuery, university: university });
      fetchUniversities();
      fetchPrograms();
      fetchEnrollments(1, { university: university });
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchEnrollments(_page);
  };

  const onUniversityChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      university: value,
      program: 'all',
      major: 'all',
    });
    setLoading(true);
    await fetchPrograms(value);
    setLoading(false);
  };

  const onCourseChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      program: value,
      major: 'all',
      project: 'all',
    });
    setLoading(true);
    await fetchProgramProjects(value);
    setLoading(false);
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchEnrollments(1);
  };

  const currentCourses = get(courses, filterQuery.university || get(me, 'university_id'));
  const uniqueCourses = uniqBy(currentCourses, 'program_id');

  let majors = [];
  let disableMajor = false;
  if (filterQuery.program !== 'all' && currentCourses) {
    const _courses = currentCourses.filter((_course) => _course.program_id === filterQuery.program);
    majors = orderBy(uniqBy(_courses, 'majorId'), 'majorId');
    if (majors.length <= 1) {
      disableMajor = true;
    }

    console.log(filterQuery.program, _courses);
  } else {
    disableMajor = true;
  }
  let disableProject = false;
  if (programProjects.length <= 1) {
    disableProject = true;
  }

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            รายชื่อผู้สมัคร
            <div style={{ float: 'right' }}>
              {/* <Button
                type="primary"
                onClick={() => {
                  setAddModalVisible(true);
                }}
              >
                เพิ่มการรับสมัครใหม่
              </Button> */}
            </div>
          </h1>

          <div className="sec-box">
            <div className="sec-form">
              <div className="col-50">
                <label>ค้นจากชื่อ, นามสกุล, รหัสประจำตัว</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>
              <div className="col-25">
                <label>
                  มหาวิทยาลัย
                  <Select
                    showSearch
                    disabled={get(me, 'role') !== 'super'}
                    style={{ width: '100%' }}
                    value={get(filterQuery, 'university')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={onUniversityChange}
                    // value={get(filterQuery, 'university')}
                    filterOption={filterLabelAndValue}
                  >
                    <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                      ทั้งหมด
                    </Option>
                    ;
                    {universities.map((university) => {
                      return (
                        <Option
                          key={university.university_id}
                          value={university.university_id}
                          label={university.university_name}
                        >
                          {university.university_name}
                        </Option>
                      );
                    })}
                  </Select>
                </label>
              </div>
              <div className="col-25">
                <label>
                  ชื่อหลักสูตร
                  <div>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onCourseChange}
                      value={get(filterQuery, 'program')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value="all" label="หลักสูตรทั้งหมด">
                        หลักสูตรทั้งหมด
                      </Option>
                      {(uniqueCourses || []).map((course, i) => {
                        return (
                          <Option key={i} value={course.program_id} label={course.program_name_th}>
                            {course.program_id}: {course.program_name_th}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>
              <div className="col-25">
                <label>
                  โครงการ
                  <div>
                    <Select
                      showSearch
                      disabled={disableProject}
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        setFilterQuery({
                          ...filterQuery,
                          project: value,
                        });
                      }}
                      value={get(filterQuery, 'project')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value="all" label="ทั้งหมด">
                        ทั้งหมด
                      </Option>
                      {(programProjects || []).map((programProject, i) => {
                        return (
                          <Option key={i} value={programProject.project_id} label={programProject.project_name_th}>
                            {programProject.project_id}: {programProject.project_name_th}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>
              <div className="col-25">
                <label>
                  รอบ
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => setFilterQuery({ ...filterQuery, type: value })}
                      value={get(filterQuery, 'type')}
                    >
                      <Option key={'all'} value="all" label="รอบทั้งหมด">
                        รอบทั้งหมด
                      </Option>
                      {(ROUNDS || []).map((round, i) => {
                        return (
                          <Option key={i} value={round.value} label={round.label}>
                            {round.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </label>
              </div>
              <div className="col-25">
                <label>
                  สถานะในระบบ
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => setFilterQuery({ ...filterQuery, status: value })}
                      value={get(filterQuery, 'status')}
                    >
                      <Option key={'all'} value="all" label="สถานะ">
                        ทั้งหมด
                      </Option>
                      {TCAS_STATUSES.map((status, i) => {
                        return (
                          <Option key={i} value={status.value} label={status.label}>
                            {status.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchEnrollments(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {enrollments.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลการรับสมัคร</th>
                </tr>
              </tbody>
            </table>
          )}
          {enrollments.length > 0 && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr>
                    <th>รหัสประจำตัวประชาชน</th>
                    <th>รหัสหลักสูตร</th>
                    <th>รอบที่สมัคร</th>
                    <th>ชื่อ - นามสกุล</th>

                    <th>ปีการศึกษาที่จบ</th>

                    <th>เบอร์ติดต่อ</th>
                    <th>สถานะในระบบ</th>
                    <th>อันดับ(รอบ 3)</th>
                  </tr>
                </thead>
                <tbody>
                  {enrollments.map((enrollment, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: enrollment.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    const student = enrollment.student
                      ? enrollment.student
                      : {
                          ...enrollment,
                          _id: null,
                          first_name: enrollment.first_name_th,
                          last_name: enrollment.last_name_th,
                        };
                    const dispalyId = (id) => (id === '0' || !id ? '-' : id);
                    const program = get(enrollment, 'program_project.program');
                    const programProject = enrollment.program_project || {};

                    return (
                      <tr key={i}>
                        <td>
                          {student._id && me.role !== 'staff' ? (
                            <Link to={`/students/${student._id}`}>{student.citizen_id}</Link>
                          ) : (
                            <span>
                              {/* [ยังไม่ได้ลงทะเบียน]
                              <br /> */}
                              {student.citizen_id}
                            </span>
                          )}
                          {me.role === 'super' && ['2', '8'].indexOf(enrollment.tcas_status) > -1 && (
                            <a
                              onClick={() => {
                                setUpdateCitizenModalVisible(true);
                                setSelectedEnrollmentOffer(enrollment);
                              }}
                            >
                              <EditOutlined />
                            </a>
                          )}
                        </td>
                        <td>
                          <div>
                            <b>PRG</b>

                            <Link to={`/programs/${programProject._id}`}>{enrollment.program_id}</Link>
                          </div>
                          <div>
                            <b>MJR</b> {dispalyId(enrollment.major_id)}
                          </div>
                          <div>
                            <b>PRJ</b> {dispalyId(enrollment.project_id)}
                          </div>
                        </td>
                        <td className="text-center">
                          <div>รอบ {enrollment.type.split('_')[0]}</div>
                          <div>{_.find(ROUNDS, { value: enrollment.type }).label}</div>
                        </td>

                        {/* 
                        <td>
                          {program && (
                            <>
                              <div>{program && program.program_name_th}</div>
                              <div>{program && dispalyId(program.major_name_th)}</div>
                              <div>{dispalyId(programProject.project_name_th)}</div>
                            </>
                          )}
                        </td> */}

                        <td>
                          {student.first_name} {student.last_name}
                        </td>
                        <td>
                          <div>{student.school_name}</div>
                          <div>ปีการศึกษาที่จบ {student.school_year}</div>
                        </td>

                        <td>{student.telephone}</td>
                        <td>
                          {find(TCAS_STATUSES, { value: enrollment.tcas_status }).label}

                          {me.role === 'super' && ['1', '2', '8'].indexOf(enrollment.tcas_status) > -1 && (
                            <a
                              onClick={() => {
                                setUpdateStatusModalVisible(true);
                                setSelectedEnrollmentOffer(enrollment);
                              }}
                            >
                              <EditOutlined />
                            </a>
                          )}
                        </td>
                        <td className="text-center">
                          <div>{enrollment.type === '3_2567' && enrollment.priority}</div>
                        </td>
                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={studentCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>

      {updateStatusModalVisible && (
        <UpdateStatusModal
          setIsVisible={setUpdateStatusModalVisible}
          isVisible={updateStatusModalVisible}
          enrollmentOffer={selectedEnrollmentOffer}
          onClose={() => {
            setUpdateStatusModalVisible(false);
            fetchEnrollments();
          }}
        ></UpdateStatusModal>
      )}
      {updateCitizenModalVisible && (
        <UpdateCitizenModal
          setIsVisible={setUpdateCitizenModalVisible}
          isVisible={updateCitizenModalVisible}
          enrollmentOffer={selectedEnrollmentOffer}
          onClose={() => {
            setUpdateCitizenModalVisible(false);
            fetchEnrollments();
          }}
        ></UpdateCitizenModal>
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
