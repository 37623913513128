import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import cn from 'classnames';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import useMyUniversity from 'hooks/useMyUniversity';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  message,
  Input,
  Upload,
  Radio,
  Spin,
  InputNumber,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import DownloadModal from './DownloadModal';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';
import OtpModal from './OtpModal';
import useRound from 'containers/Rounds/useRound';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [requestParams, setRequestParams] = useState({});
  const [modalError, setModalError] = useState('');
  const adminConfig = useSelector((state) => state.configs || {});
  const { university } = useMyUniversity();
  if (!university) {
    return null;
  }

  const createExportFile = async (round, { onlySubmit, onlyCancel, fullStudent }, otp, refCode) => {
    try {
      setLoading(true);
      const { filePath } = await callApi({
        url: `/enrollment-offers/rounds/${round}/export`,
        body: {
          onlySubmit,
          onlyCancel,
          otp: otp,
          refCode: refCode,
          fullStudent: round === '3' && fullStudent,
        },
        method: 'POST',
      });
      download(filePath);
      setOpenOtpModal(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setModalError(_.get(err, 'reason.message'));
      setLoading(false);
    }
  };

  const onExportButtonClick = async (roundNumber, onlySubmit, onlyCancel, fullStudent) => {
    if (!me.telephone) {
      Modal.info({
        title: 'กรุณาใส่เบอร์มือถือก่อนทำการดึงข้อมูล',
        onOk() {
          window.location.href = '/users/edit';
        },
      });
      return;
    }
    setOpenOtpModal(true);
    setRequestParams({ roundNumber, onlySubmit, onlyCancel, fullStudent });
  };
  // createExportFile(roundNumber, onlySubmit, otp, ref_code);

  return (
    <div>
      <Spin spinning={openOtpModal} delay={0}>
        <div className="t-main">
          <h1>ดาวน์โหลดข้อมูลผู้สมัคร</h1>
          <p>
            คลิกที่ไอคอนเพื่อดาวน์โหลดไฟล์ในแต่ละรอบ สามารถเลือกเฉพาะผู้ที่ยืนยันสิทธิ์
            หรือเลือกข้อมูลผู้สมัครทั้งหมดได้ที่
          </p>
          <ul className="sec-import -download">
            {['1', '2', '3', '41', '42'].map((roundNumber) => {
              const roundField = `round_${roundNumber}`;
              const round = find(ROUNDS, { value: `${roundNumber}_2567` });
              const roundData = university[roundField];
              if (!roundData) {
                return <></>;
              }
              const formattedRoundData = useRound(roundData);
              let displayDownloadBtn = roundData.latest_enrollment_result_file;
              let displayAllEnrollmentDownloadBtn = roundData.latest_enrollment_result_file;
              let displayCancelDownloadBtn = adminConfig[`download_cancel_round_${roundNumber}_active`];
              if (roundNumber === '3') {
                displayDownloadBtn = adminConfig.isopen_for_round_3_download_enrollment_result;
                displayAllEnrollmentDownloadBtn = adminConfig.isopen_for_round_3_download_enrollment_result;

                // displayCancelDownloadBtn = false;
              }
              if (roundNumber === '41' || roundNumber === '42') {
                displayCancelDownloadBtn = false;
              }

              if (!adminConfig[`isopen_for_round_${roundNumber}_download_enrollment_result`]) {
                displayDownloadBtn = false;
                displayAllEnrollmentDownloadBtn = false;
              }

              // displayDownloadBtn = false;

              return (
                <li className={`round-${roundNumber}`}>
                  <h2>
                    <b className="num">{roundNumber}</b>
                    <span>{round.label}</span>
                  </h2>

                  <div className={cn('file', { '-none': !displayDownloadBtn })}>
                    <a onClick={() => displayDownloadBtn && onExportButtonClick(roundNumber, true, false)}>
                      <i className="i-course"></i>
                      <b>{displayDownloadBtn ? 'เฉพาะผู้ยืนยันสิทธิ์' : 'ยังไม่มีข้อมูลผู้ยืนยันสิทธิ์'} </b>
                      {/* <small>confirmed.csv</small> */}
                    </a>
                    <a
                      onClick={() =>
                        displayAllEnrollmentDownloadBtn && onExportButtonClick(roundNumber, false, false, true)
                      }
                    >
                      {displayAllEnrollmentDownloadBtn ? (
                        <div>
                          <i className="i-course"></i>
                          <b> {roundNumber === '3' ? 'คะแนนแยกรายวิชา' : 'ผู้สมัครทั้งหมด'} </b>
                        </div>
                      ) : (
                        <b>ยังไม่มีข้อมูลผู้สมัคร</b>
                      )}

                      {/* <small>all.csv</small> */}
                    </a>

                    {roundNumber === '3' && displayAllEnrollmentDownloadBtn && (
                      <>
                        <div>
                          <a
                            onClick={() =>
                              displayAllEnrollmentDownloadBtn && onExportButtonClick(roundNumber, false, false)
                            }
                          >
                            <div>
                              <i className="i-course"></i>
                              <b> ผู้ผ่านการคัดเลือกทั้งหมด</b>
                            </div>

                            {/* <small>all.csv</small> */}
                          </a>
                        </div>
                      </>
                    )}

                    {displayCancelDownloadBtn ? (
                      <div>
                        <a onClick={() => displayCancelDownloadBtn && onExportButtonClick(roundNumber, false, true)}>
                          <i className="i-course"></i>
                          <b>สละสิทธิ์เมื่อวันที่</b>

                          <b>{formattedRoundData.cancelDateString}</b>
                        </a>
                      </div>
                    ) : (
                      <span>ยังไม่มีการสละสิทธิ์</span>
                    )}
                  </div>
                </li>
              );

              // if (adminConfig.current_round !== roundNumber) {
              //   return <></>;
              // }
            })}
          </ul>
        </div>

        {openOtpModal && (
          <OtpModal
            setIsVisible={setOpenOtpModal}
            isVisible={openOtpModal}
            requestParams={requestParams}
            errorMessage={modalError}
            onSubmitOtp={(ref_code, otp) => {
              createExportFile(requestParams.roundNumber, requestParams, otp, ref_code);
            }}
            downloading={loading}
            onCancel={() => {
              setModalError('');
              setOpenOtpModal(false);
            }}
          ></OtpModal>
        )}
      </Spin>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
