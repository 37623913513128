import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';

// Components
import CONSTANTS from 'constants/constants';
import PROGRAM_CODES from 'constants/program-codes.json';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ student, setIsVisible, onClose, isVisible, updateFields = [] }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const subjects = useSelector((state) => state.subjects);

  const updateStudent = async (values) => {
    try {
      setLoading(true);

      const requestBody = { ...values };

      const reqParams = {
        url: `/students/${student._id}`,
        method: 'put',
        body: requestBody,
      };

      await callApi(reqParams);

      setLoading(false);
      setIsVisible(false);
      onClose();
      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  const validatePhone = (rule, value) => {
    if (!isMobilePhone(value, 'th-TH')) {
      throw new Error(`เบอร์โทรศัพท์ไม่ถูกต้อง`);
    }
  };
  const validateMinMaxValue = (rule, value, field) => {
    const subject = find(subjects, { key: field.key });
    console.log(subject);
    if (subject) {
      const minValue = subject.min_value || 0;
      const maxValue = subject.max_value || 999999;
      if (value > maxValue) {
        throw new Error(`ค่าเกินค่าสูงสุด`);
      }
      if (value < minValue) {
        throw new Error(`ค่าน้อยกว่าค่าต่ำสุด`);
      }
    }
  };

  const customValidate = async (rule, value, field) => {
    // if (field.key.indexOf('phone') > -1) {
    //   return validatePhone(rule, value);
    // }
    if (field.type === 'number') {
      return validateMinMaxValue(rule, value, field);
    }
  };
  if (!student) {
    return null;
  }

  return (
    <Modal
      title={`${student.title}
      ${student.first_name} ${student.last_name}`}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          บันทึก
        </Button>,
      ]}
      onCancel={() => setIsVisible(false)}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={updateStudent}>
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
          {updateFields.map((field) => {
            const renderProgramCodeDropdown = () => {
              return (
                <Select>
                  {_.map(PROGRAM_CODES, (CODE, key) => {
                    return <Select.Option value={key}>{CODE}</Select.Option>;
                  })}
                </Select>
              );
            };
            const renderInput = () => {
              if (field.key === 'program_code') {
                return renderProgramCodeDropdown();
              } else if (field.type === 'number') {
                return <InputNumber />;
              } else {
                return <Input />;
              }
            };
            return (
              <Col span={24}>
                <Form.Item
                  label={field.label}
                  labelCol={{ span: 12 }}
                  labelAlign={'left'}
                  name={field.key}
                  rules={[
                    { required: field.required, message: `จำเป็นต้องใส่ ${field.label}` },
                    { validator: (rule, value) => customValidate(rule, value, field) },
                  ]}
                  initialValue={student[field.key]}
                >
                  {renderInput()}
                </Form.Item>
              </Col>
            );
          })}
        </Row>

        <div></div>
      </Form>
    </Modal>
  );
};
