import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
const applicant_statuses = [
  { value: '1', label: 'ผู้สมัคร', tcas_status: '1' },
  { value: '2', label: 'ผ่านการคัดเลือก', tcas_status: '2' },
  { value: '3', label: 'ไม่ผ่านการคัดเลือก', tcas_status: '8' },
  { value: '4', label: 'ผ่านสัมภาษณ์ และยืนยันเข้าศึกษา', tcas_status: '3' },
  { value: '5', label: 'ขาดสอบสัมภาษณ์' },

  { value: '6', label: 'ไม่ประสงค์เข้าศึกษา' },
  { value: '7', label: 'ขาดคุณสมบัติตามที่กำหนด' },
];

const { Panel } = Collapse;

const Exam = ({ student }) => {
  // let { studentId } = useParams();
  const [enrollments, setEnrollments] = useState([]);
  const subjects = useSelector((state) => state.subjects || []);

  const fetchEnrollments = async () => {
    const result = await callApi({
      url: `/students/${student.citizen_id}/round-3/enrollments`,
    });

    // result.forEach((app) => (app.index = EXAM_SUBJECTS.index[app.subject_name]));
    setEnrollments(result);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchEnrollments();
    }
  }, [student]);

  if (enrollments.length === 0) {
    return <div></div>;
  }

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>
      <h2>ผลการคัดเลือกรอบ 3</h2>

      <table className="t-table -pticket">
        <thead>
          <th>อันดับ</th>
          <th>รหัสหลักสูตร</th>
          <th>มหาวิทยาลัย</th>
          <th>หลักสูตร</th>
          <th>คะแนนที่ได้</th>
          <th>สูงสุด/ต่ำสุด</th>
          <th>
            สูงสุด/ต่ำสุด
            <br />
            (DS)
          </th>
          <th>
            เปิดรับ/
            <br />
            คงเหลือ DS
          </th>

          <th>ผลการคัดเลือก</th>
        </thead>
        <tbody>
          {enrollments.map((enrollment, j) => {
            const project = enrollment.program_project;
            const dispalyId = (id) => (id === '0' || !id ? '-' : id);

            return (
              <tr>
                <td>
                  <b>{enrollment.priority}</b>
                </td>
                <td>
                  <div>
                    <b>PRG</b> {enrollment.program_id}
                  </div>
                  <div>
                    <b>MJR</b> {dispalyId(enrollment.major_id)}
                  </div>
                  <div>
                    <b>PRJ</b> {dispalyId(enrollment.project_id)}
                  </div>
                </td>
                <td>
                  <b>
                    {project.program.university_name_th} {project.program.campus_name_th}
                    <br />
                    {project.program.faculty_name_th}
                  </b>
                </td>
                <td>
                  <div>สาขา {project.program.field_name_th}</div>
                  {project.program.major_name_th && project.program.major_name_th !== '0' && (
                    <div>วิชาเอก{project.program.major_name_th}</div>
                  )}
                  <div>{project.program.program_name_th}</div>
                  {project.project_name_th && project.project_name_th !== '0' && (
                    <div>โครงการ{project.project_name_th}</div>
                  )}
                  {project.program.program_type_name_th && <div>({project.program.program_type_name_th})</div>}
                </td>
                <td>
                  <b>{enrollment.score}</b>
                  {enrollment.ranking ? <div>อันดับ {enrollment.ranking}</div> : ''}
                  {enrollment.ds_ranking ? <div>อันดับ(ds) {enrollment.ds_ranking}</div> : ''}
                </td>
                <td>
                  <div>
                    <b>{project.max_score}</b>
                  </div>
                  <div>
                    <b>{project.min_score}</b>
                  </div>
                </td>

                <td>
                  <div>
                    <b>{project.max_score_ds}</b>
                  </div>
                  <div>
                    <b>{project.min_score_ds}</b>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <b>{project.receive_student_number}</b>
                    </div>
                    <div>
                      <b>{project.receive_student_number_ds}</b>
                    </div>
                  </div>
                </td>
                <td>
                  {enrollment.applicant_status === '2' &&
                    (enrollment.tcas_status === '2' || enrollment.tcas_status === '3') && (
                      <div className="color-green">
                        <b>{_.find(applicant_statuses, { value: enrollment.applicant_status }).label}</b>
                      </div>
                    )}
                  {enrollment.applicant_status === '4' && (
                    <div className="color-green">
                      <b>{_.find(applicant_statuses, { value: enrollment.applicant_status }).label}</b>
                    </div>
                  )}
                  {enrollment.applicant_status === '5' && (
                    <div className="color-red">
                      <b>{_.find(applicant_statuses, { value: enrollment.applicant_status }).label}</b>
                    </div>
                  )}
                  {enrollment.applicant_status === '6' && (
                    <div className="color-red">
                      <b>{_.find(applicant_statuses, { value: enrollment.applicant_status }).label}</b>
                    </div>
                  )}
                  {enrollment.applicant_status === '7' && (
                    <div className="color-red">
                      <b>{_.find(applicant_statuses, { value: enrollment.applicant_status }).label}</b>
                    </div>
                  )}
                  {enrollment.tcas_status === '9' && (
                    <div>
                      <b>ได้อันดับที่ดีกว่า</b>
                    </div>
                  )}
                  {enrollment.tcas_status === '8' && (
                    <div className="color-red">
                      <b>ไม่ผ่านการคัดเลือก</b>
                    </div>
                  )}
                  {enrollment.tcas_status === '5' && (
                    <div className="color-red">
                      <b>ไม่ใช้สิทธิ์</b>
                    </div>
                  )}
                  {enrollment.tcas_status === '10' && (
                    <div className="color-red">
                      <b>ไม่เข้ามาทำใดๆ ในระบบ</b>
                    </div>
                  )}
                  {enrollment.tcas_status === '11' && (
                    <div className="color-red">
                      <b>ขอประมวลผลรอบ 2 ถ้าไม่ได้อันดับดีกว่าขอสละสิทธิ์</b>
                    </div>
                  )}
                  {enrollment.tcas_status === '12' && (
                    <div className="color-red">
                      <b>เยียวยา</b>
                    </div>
                  )}

                  {enrollment.double_sort === true && (
                    <div>
                      <b>(ขอประมวลผลรอบที่ 2)</b>
                    </div>
                  )}

                  {enrollment.pass === true && (
                    <div>
                      <b>(ติดรอบแรก)</b>
                    </div>
                  )}
                  {enrollment.pass !== true && enrollment.tcas_status === '1' && (
                    <div className="color-red">
                      <b>ไม่ผ่านการคัดเลือก</b>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}

          {/* {fullOrder.enrollments.map((app) => {
              return (
                <tr>
                  <td>{EXAM_SUBJECTS.labels[app.subject_name]}</td>
                </tr>
              );
            })} */}
        </tbody>
      </table>

      <hr></hr>
    </div>
  );
};
export default Exam;
