const AnswerTable = ({ answers }) => {
  if (!answers) {
    return <></>;
  }
  const questionCount = answers.length;
  console.log(questionCount, answers.length, answers);
  return (
    <>
      <div className="cbt-ans">
        <table>
          <thead>
            <tr>
              <th>ข้อ</th>
              <th>ตอบ</th>
              <th>เฉลย</th>
              <th>คะแนน</th>
              <th>คะแนนรวม</th>
            </tr>
          </thead>
          <tbody>
            {Array(Math.floor(questionCount / 2))
              .fill()
              .map((__, index) => {
                const scoreString = answers[index].score;
                const formattedScore = _.trimEnd(_.trimEnd(scoreString, '0'), '.');
                const accuScore = answers[index].accu_score.toFixed(5);
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{answers[index].answer}</td>
                    <td>{answers[index].key}</td>
                    <td style={{ color: formattedScore === '0' ? 'red' : 'green' }}>{formattedScore}</td>
                    <td>{accuScore}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>ข้อ</th>
              <th>ตอบ</th>
              <th>เฉลย</th>
              <th>คะแนน</th>
              <th>คะแนนรวม</th>
            </tr>
          </thead>
          <tbody>
            {Array(Math.ceil(questionCount / 2))
              .fill()
              .map((__, index) => {
                const _index = Math.floor(questionCount / 2) + index;
                const scoreString = answers[_index].score;
                const formattedScore = _.trimEnd(_.trimEnd(scoreString, '0'), '.');
                const accuScore = answers[_index].accu_score.toFixed(5);
                return (
                  <tr key={index}>
                    <td>{_index + 1}.</td>
                    <td>{answers[_index].answer}</td>
                    <td>{answers[_index].key}</td>
                    <td style={{ color: formattedScore === '0' ? 'red' : 'green' }}>{formattedScore}</td>
                    <td>{accuScore}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnswerTable;
