import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import PROGRAM_CODES from 'constants/program-codes.json';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ program, setIsVisible, onClose, isVisible, updateFields = [], endPoint }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects);

  const updateProgram = async (values) => {
    try {
      setLoading(true);

      const requestBody = { ...values };

      const reqParams = {
        url: `${endPoint}/${me.role === 'staff' ? 'staff/' : ''}${program._id}`,
        method: 'put',
        body: requestBody,
      };

      await callApi(reqParams);

      setLoading(false);
      setIsVisible(false);
      onClose();
      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  const customValidate = async (rule, value, field) => {
    // if (field.key.indexOf('phone') > -1) {
    //   return validatePhone(rule, value);
    // }
    // if (field.type === 'number') {
    //   // return validateMinMaxValue(rule, value, field);
    // }
  };
  if (!program) {
    return null;
  }
  if (me.role === 'junior' || me.role === 'senior') {
    return null;
  }
  const getDisabledStatus = (field) => {
    if (field.onlySuperAdminEdit && me.role !== 'super') {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={`${program.program_id}
      ${program.program_name_th}`}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          บันทึก
        </Button>,
      ]}
      onCancel={() => setIsVisible(false)}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={updateProgram}>
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
          {updateFields.map((field) => {
            const renderInput = () => {
              if (field.type === 'number') {
                if (field.key === 'receive_student_number') {
                  let minReceiveStudentNumber = 0;
                  if (me.role === 'staff') {
                    minReceiveStudentNumber = program.receive_student_number;
                  }

                  return <InputNumber disabled={getDisabledStatus(field)} min={minReceiveStudentNumber} />;
                }
                return <InputNumber disabled={getDisabledStatus(field)} min={10} />;
              } else if (field.type === 'textarea') {
                return <Input.TextArea disabled={getDisabledStatus(field)} />;
              } else {
                return <Input disabled={getDisabledStatus(field)} />;
              }
            };
            if (!program.major_id || (program.major_id === '0' && field.key.indexOf('major') > -1)) {
              return <Col />;
            }
            const rules = [
              {
                required: field.required,
                message: `จำเป็นต้องใส่ ${field.label}`,
              },
              { validator: (rule, value) => customValidate(rule, value, field) },
            ];

            return (
              <Col span={24}>
                <Form.Item label={field.label} name={field.key} rules={rules} initialValue={program[field.key]}>
                  {renderInput()}
                </Form.Item>
              </Col>
            );
          })}
        </Row>

        <div></div>
      </Form>
    </Modal>
  );
};
