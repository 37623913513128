import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import moment from 'moment';

import useEditRound from './useEditRound';

const formatDateRange = ([startMoment, endMoment]) =>
  startMoment.month() === endMoment.month()
    ? `${startMoment.format('D')} - ${endMoment.format('D MMM YY')}`
    : `${startMoment.format('D MMM')} - ${endMoment.format('D MMM YY')}`;

const EditModal = ({ roundInfo, roundIndex, onCancel, onUpdateRound, setUniversity }) => {
  if (!roundIndex) return null;
  const {
    roundName,
    roundInfo: currentRound,
    existedKeys,
    onRoundUpdate,
    isDeletable,

    filePath,
    uploadedAt,

    registerDateString,
    intervieweeAnnounceDateString,
    intervieweeAnnounceDate2String,
    interviewDateString,
    candidateAnnounceDateString,
    confirmDateString,
    confirmDate2String,
    cancelDateString,
    studentAnnounceDateString,
    deletedDisabled,

    agendaOptions,
    selectedKey,
    setSelectedKey,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    onAgendaAdd,

    uploadRef,
    selectedFile,
    setSelectedFile,
    onUpload,
    uploading,
    uploadProgress,
    onFileRemove,
    fileRemoving,
  } = useEditRound(roundInfo, roundIndex);

  useEffect(() => onUpdateRound(currentRound), [currentRound]);

  const validOptions = agendaOptions.filter(({ value }) => !existedKeys.includes(value));
  return (
    <Modal visible={Boolean(roundIndex)} onCancel={onCancel} width={720} footer={null}>
      <h2>{roundName}</h2>
      {roundIndex !== 5 && (
        <div>
          <table className="event">
            <tbody>
              {registerDateString && (
                <tr>
                  <td>
                    {isDeletable('register_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            register_start_date: null,
                            register_end_date: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>รับสมัคร</td>
                  <td>{registerDateString}</td>
                </tr>
              )}
              {intervieweeAnnounceDateString && (
                <tr>
                  <td>
                    {isDeletable('interviewee_announce_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            interviewee_announce_start_date: currentRound.interviewee_announce_start_date_2,
                            interviewee_announce_end_date: currentRound.interviewee_announce_end_date_2,
                            interviewee_announce_start_date_2: null,
                            interviewee_announce_end_date_2: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>
                    ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์
                    {intervieweeAnnounceDate2String && ' ครั้งที่ 1'}
                  </td>
                  <td>{intervieweeAnnounceDateString}</td>
                </tr>
              )}
              {intervieweeAnnounceDate2String && (
                <tr>
                  <td>
                    {isDeletable('interviewee_announce_date_2') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            interviewee_announce_start_date_2: null,
                            interviewee_announce_end_date_2: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์ ครั้งที่ 2</td>
                  <td>{intervieweeAnnounceDate2String}</td>
                </tr>
              )}
              {interviewDateString && (
                <tr>
                  <td>
                    {isDeletable('interview_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            interview_start_date: null,
                            interview_end_date: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>สอบสัมภาษณ์</td>
                  <td>ภายใน {interviewDateString}</td>
                </tr>
              )}
              {candidateAnnounceDateString && (
                <tr>
                  <td>
                    {isDeletable('candidate_announce_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            candidate_announce_start_date: null,
                            candidate_announce_end_date: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>ประกาศรายชื่อผู้ผ่านการคัดเลือก</td>
                  <td>{candidateAnnounceDateString}</td>
                </tr>
              )}
              {confirmDateString && (
                <tr>
                  <td>
                    {isDeletable('confirm_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            confirm_start_date: currentRound.confirm_start_date_2,
                            confirm_end_date: currentRound.confirm_end_date_2,
                            confirm_start_date_2: null,
                            confirm_end_date_2: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>ยืนยันสิทธิ์ TCAS{confirmDate2String && ' ครั้งที่ 1'}</td>
                  <td>{confirmDateString}</td>
                </tr>
              )}
              {confirmDate2String && (
                <tr>
                  <td>
                    {isDeletable('confirm_date_2') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            confirm_start_date_2: null,
                            confirm_end_date_2: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>ยืนยันสิทธิ์ TCAS ครั้งที่ 2</td>
                  <td>{confirmDate2String}</td>
                </tr>
              )}
              {cancelDateString && (
                <tr>
                  <td>
                    {isDeletable('cancel_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            cancel_start_date: null,
                            cancel_end_date: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>ยกเลิกสิทธิ์ TCAS</td>
                  <td>{cancelDateString}</td>
                </tr>
              )}
              {studentAnnounceDateString && (
                <tr>
                  <td>
                    {isDeletable('student_announce_date') && (
                      <i
                        className="i-del"
                        onClick={() =>
                          onRoundUpdate({
                            student_announce_start_date: null,
                            student_announce_end_date: null,
                          })
                        }
                      />
                    )}
                  </td>
                  <td>ประกาศผลผู้มีสิทธิ์เข้าศึกษา</td>
                  <td>{studentAnnounceDateString}</td>
                </tr>
              )}
            </tbody>
          </table>
          {Boolean(validOptions.length) && (
            <div>
              <h3>เพิ่มกำหนดการ</h3>
              <div className="sec-form">
                <div className="col-25">
                  <label>ชื่อกำหนดการ</label>
                  <select value={selectedKey} onChange={(e) => setSelectedKey(e.target.value)}>
                    {validOptions.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-25">
                  <label>วันที่เริ่มต้น</label>
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={selectedStartDate}
                    max={selectedEndDate}
                    onChange={(e) => setSelectedStartDate(e.target.value)}
                  />
                </div>
                <div className="col-25">
                  <label>วันที่สิ้นสุด</label>
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={selectedEndDate}
                    min={selectedStartDate}
                    onChange={(e) => setSelectedEndDate(e.target.value)}
                  />
                </div>
                <div className="col-25 col-action">
                  <a className="btn-main" onClick={onAgendaAdd}>
                    เพิ่มรายการ
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <hr className="hr-full" />
      <h3>ไฟล์สำหรับเผยแพร่ (ขนาดไม่เกิน 32MB)</h3>
      {/*<p>หากเพิ่มไฟล์ใหม่ ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์ใหม่ไปแทนที่</p>*/}
      <p>
        {filePath
          ? 'เลือกไฟล์ที่ต้องการเผยแพร่ ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์นี้ไปแทนที่ไฟล์เก่า'
          : 'เลือกไฟล์ที่ต้องการเผยแพร่ แล้วคลิกปุ่มนำเข้าข้อมูล'}
      </p>
      {filePath && (
        <p className="s-flex">
          <i className="i-del" onClick={() => onFileRemove(setUniversity)} />
          <i className="i-book" /> {filePath.split('/').pop()}
        </p>
      )}
      <div className="sec-form">
        <div className="col-75">
          <input ref={uploadRef} type="file" accept=".pdf" onChange={(e) => setSelectedFile(e.target.files[0])} />
          <div className={uploadProgress ? 't-progress active' : 't-progress'}>
            <b style={{ width: `${uploadProgress * 0.99}%` }}></b>
          </div>
        </div>
        <div className="col-25">
          <a className="btn-main" onClick={() => onUpload(setUniversity)}>
            {uploading ? (
              <span>กำลังนำเข้าข้อมูล</span>
            ) : (
              <span>
                <i className="i-upload" /> นำเข้าข้อมูล
              </span>
            )}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
