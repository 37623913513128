import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import EXAM_SUBJECTS from 'constants/exam-subjects';
const A_LV_KEYS = EXAM_SUBJECTS.a_lv_keys;

const { Panel } = Collapse;

const Exam = ({ student }) => {
  // let { studentId } = useParams();
  const dispatch = useDispatch();
  const [examApplications, setExamApplications] = useState([]);

  const subjects = useSelector((state) => state.subjects || []);
  const sites = useSelector((state) => state.sites || []);

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchSites = async () => {
    if (sites.length > 0) {
      return;
    }
    try {
      const _sites = await callApi({ url: '/exam-sites' });
      const sitesBySiteCode = _.keyBy(_sites, 'site_code');
      dispatch({ type: 'UPDATE_SITES', data: sitesBySiteCode });
    } catch (error) {
      console.log('errror', error);
    }

    // setSites(_sites.data);
  };

  useEffect(() => {
    fetchSubjects();
    fetchSites();
  }, []);

  const fetchApplication = async () => {
    const result = await callApi({
      url: `/students/${student.citizen_id}/exam-applications`,
    });
    setExamApplications(result);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchApplication();
    }
  }, [student]);

  if (!examApplications) {
    return <div></div>;
  }

  const appsGroupByDay = _.groupBy(examApplications, 'day');
  let subjectsByKey = _.keyBy(subjects, 'key');
  subjectsByKey = {
    ...subjectsByKey,
  };

  // const repliesByRound = _.groupBy(replies, 'type');
  const cbtExamApplication = _.find(examApplications, { selected_exam_type: 'cbt' });

  // console.log(repliesByRound);

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>
      {cbtExamApplication && (
        <div>
          <div className="sec-box" style={{ width: '50%' }}>
            <div>ทดลองสอบด้วยเครื่องคอมพิวเตอร์</div>
            <ul>
              <li>
                วันทดลองสอบ <b>{cbtExamApplication.test_system_date}</b>
              </li>
              <li>
                เวลา <b>{cbtExamApplication.test_system_time}</b>
              </li>
              <li>
                สนามสอบ <b>{cbtExamApplication.test_system_site_name}</b>
              </li>
              <li>
                อาคาร{' '}
                <b>
                  {cbtExamApplication.test_system_building &&
                  cbtExamApplication.test_system_building.startsWith('อาคาร')
                    ? cbtExamApplication.test_system_building.replace('อาคาร', '')
                    : cbtExamApplication.test_system_building}
                </b>{' '}
                ห้อง{' '}
                <b>
                  {cbtExamApplication.test_system_room && cbtExamApplication.test_system_room.startsWith('ห้อง')
                    ? cbtExamApplication.test_system_room.replace('ห้อง', '')
                    : cbtExamApplication.test_system_room}
                </b>
              </li>
            </ul>
          </div>
        </div>
      )}

      {_.map(appsGroupByDay, (apps, day) => {
        return (
          <div>
            <div>
              <h2>วันที่ {day}</h2>
              {apps.map((app, i) => {
                const site = sites[app.selected_place] || {
                  app_id: '-',
                  school_name: '-',
                };
                const isCbt = app.cbt_place_1;

                let subject = {};
                if (subjectsByKey[app.subject_name.toLowerCase()]) {
                  subject = subjectsByKey[app.subject_name.toLowerCase()];
                } else {
                  const normalizeSubject = A_LV_KEYS[app.subject_name];
                  subject = subjectsByKey[normalizeSubject] || {};
                }

                return (
                  <Collapse>
                    <Panel
                      header={
                        <div>
                          <div style={{ float: 'right' }}>สถานที่สอบ {site.school_name}</div>
                          <div>{subject.label}</div>
                        </div>
                      }
                      key={i}
                    >
                      <ul className="r-history">
                        <li>
                          <div key={app._id} className="xm-box">
                            <ul>
                              <li>
                                เลขที่นั่งสอบ <b>{app.app_id || 'ยังไม่ได้จัดสรร'}</b>
                              </li>

                              <li>
                                สนามสอบ{' '}
                                <b>
                                  {site.school_name} {site.site_code ? `(${site.site_code})` : ''}
                                </b>
                              </li>
                              <li>
                                อาคาร <b>{app.building_name || '-'}</b> &nbsp;&nbsp; ชั้น <b>{app.floor || '-'}</b> ห้อง{' '}
                                <b>{app.room_name || '-'}</b>
                              </li>
                              <li>
                                ตำแหน่งที่นั่ง{' '}
                                <b>
                                  แถว <small>(Row)</small> {app.row || '-'} • ตอน <small>(Column)</small>{' '}
                                  {app.col || '-'}
                                </b>
                              </li>
                              <li>
                                จังหวัด <b>{site.province || '-'}</b>
                              </li>
                              <li>
                                ความต้องการพิเศษ <b>{app.special_request || '-'}</b>
                              </li>
                            </ul>
                          </div>
                          <br></br>
                          {isCbt && (
                            <>
                              <h3>สถานที่สอบคอมพิวเตอร์ที่เลือก</h3>
                              <div key={app._id} className="xm-box">
                                <ul>
                                  {_.times(5, _.constant(null)).map((a, index) => {
                                    const place = _.get(sites, `${app[`cbt_place_${index + 1}`]}.school_name`);

                                    return (
                                      <li>
                                        อันดับ {index + 1}: {place}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </>
                          )}

                          <h3>สถานที่สอบกระดาษที่เลือก</h3>
                          <div key={app._id} className="xm-box">
                            <ul>
                              {_.times(5, _.constant(null)).map((a, index) => {
                                const place = _.get(sites, `${app[`place_${index + 1}`]}.school_name`);

                                return (
                                  <li>
                                    อันดับ {index + 1}: {place}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Exam;
