import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import cn from 'classnames';
import callApi from 'helpers/callapi';
import ROUNDS from 'constants/rounds';

const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const DownloadModal = ({ setIsVisible, onClose, isVisible }) => {
  const [type, setType] = useState('1_2567');
  const [onlySubmit, setOnlySubmit] = useState(true);
  console.log(type);

  const createExportFile = async () => {
    const { filePath } = await callApi({
      url: `/enrollment-offers/rounds/${type.replace('_2567', '')}/export`,
      body: {
        onlySubmit,
      },
      method: 'POST',
    });
    download(filePath);
    setIsVisible(false);
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={() => {
        setIsVisible(false);
      }}
      width={720}
      footer={null}
    >
      <h2>ดาวน์โหลดข้อมูลการรับสมัคร</h2>
      <br />

      <div className="sec-form">
        <div className="col-25">รอบ </div>
        <div className="col-25">
          <select onChange={(e) => setType(e.target.value)} value={type}>
            {(ROUNDS || []).map((round, i) => {
              return (
                <option key={i} value={round.value} label={round.label}>
                  {round.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <h4>ตัวกรอง</h4>
        <div
          className={cn({ active: onlySubmit })}
          onClick={() => setOnlySubmit(true)}
          style={{
            width: '48%',
            border: '1px solid grey',
            padding: 25,
            textAlign: 'center',
            marginRight: '2%',
            display: 'inline-block',
          }}
        >
          เฉพาะผู้ยืนยันสิทธิ์ {onlySubmit.toString()}
        </div>
        <div
          className={cn({ active: !onlySubmit })}
          onClick={() => setOnlySubmit(false)}
          style={{
            width: '48%',
            border: '1px solid grey',
            padding: 25,
            textAlign: 'center',
            marginLeft: '2%',
            marginBottom: 25,
            display: 'inline-block',
          }}
        >
          การรับสมัครทั้งหมด {onlySubmit.toString()}
        </div>

        <Button
          block
          type="primary"
          onClick={() => {
            createExportFile();
          }}
        >
          ดาวน์โหลดข้อมูล
        </Button>
      </div>
    </Modal>
  );
};

export default DownloadModal;
