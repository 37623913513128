import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Button, Table, Pagination, Modal, Row, Col, Select, Form, message, Input, Radio } from 'antd';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ selectedOrder, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const addIpAddress = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        ip: values.ip,
      };

      await callApi({
        url: `/users/ips`,
        body: requestBody,
      });

      setLoading(false);
      setIsVisible(false);
      onClose();
      message.success('เพิ่ม IP Address สำเร็จ');
    } catch (error) {
      console.error('Error while trying to change password.', error);
      message.error(get(error, 'reason.message'));

      setLoading(false);
    }
  };

  if (!isVisible) return null;
  return (
    <Modal
      title={`เพิ่ม IP Address`}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          เพิ่ม IP Address
        </Button>,
      ]}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={addIpAddress}>
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}

          <Col span={16}>
            <Form.Item
              label="Ip Adress"
              name="ip"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'จำเป็นต้องใส่ IP Address',
                },
                {
                  // required: true,

                  pattern: new RegExp(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/),
                  message: 'รูปแบบ IP Address ไม่ถูกต้อง',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
