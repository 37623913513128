import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import EXAM_SUBJECTS from 'constants/exam-subjects';
import EXAM_SITES from 'constants/exam-sites';

const { Panel } = Collapse;
const STATUSES = [
  { label: 'ทั้งหมด', value: 'all' },
  { label: 'รอดำเนินการ', value: 'pending' },
  { label: 'จ่ายเงินแล้ว', value: 'paid' },
  { label: 'ยกเลิก', value: 'canceled' },
];

const ExamRegisterLogs = ({ examConfirmations, defaultActiveKey = ['1'], isGhost = true, orders }) => {
  const dispatch = useDispatch();
  const subjects = useSelector((state) => state.subjects || []);
  const sites = useSelector((state) => state.sites || []);

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchSites = async () => {
    if (sites.length > 0) {
      return;
    }
    try {
      const _sites = await callApi({ url: '/exam-sites' });
      const sitesBySiteCode = _.keyBy(_sites, 'site_code');
      dispatch({ type: 'UPDATE_SITES', data: sitesBySiteCode });
    } catch (error) {
      console.log('errror', error);
    }

    // setSites(_sites.data);
  };

  useEffect(() => {
    fetchSubjects();
    fetchSites();
  }, []);

  if (!examConfirmations) {
    return <div></div>;
  }
  const subjectsByKey = _.keyBy(subjects, 'key');

  const renderStatus = (status) => {
    if (!status) {
      return <></>;
    }
    const label = _.find(STATUSES, { value: status }).label;
    console.log(label);

    return (
      <>
        <b className={cn({ 'color-red': status === 'canceled', 'color-green': status === 'paid' })}>{label}</b>
      </>
    );
  };

  let isRenderNewSubject = false;

  return (
    <div>
      {examConfirmations.map((examConfirmationGroup, i) => {
        let previousConfirmationGroup =
          i + 1 < examConfirmations.length ? examConfirmations[i + 1] : { confirmationsById: [] };
        const mainExamConfirmationGroup = examConfirmationGroup.confirmationsById[0];
        let isFirstConfirmation = examConfirmations.length === i + 1;

        let isDiffSubject = false;

        if (_.get(previousConfirmationGroup, 'confirmationsById[0].type') !== mainExamConfirmationGroup.type) {
          previousConfirmationGroup = { confirmationsById: [] };
          isFirstConfirmation = true;
          isDiffSubject = true;
        }

        return (
          <div id={i}>
            <Collapse ghost={isGhost} defaultActiveKey={defaultActiveKey}>
              <Panel
                header={
                  <span>
                    การสมัคร {mainExamConfirmationGroup.type} ครั้งที่ <b>{mainExamConfirmationGroup.apply_id}</b> /
                    แก้ไขสนามสอบครั้งที่ <b>{mainExamConfirmationGroup.site_confirm_id || '-'}</b> เมื่อ{' '}
                    <b>{moment(examConfirmationGroup.created_at).add(543, 'years').format('D MMM YY HH:mm น.')}</b>
                    <b>
                      {mainExamConfirmationGroup.order_id
                        ? ` (เลขที่รายการ ${mainExamConfirmationGroup.order_id})`
                        : ''}
                    </b>
                    {mainExamConfirmationGroup.order_id && (
                      <div className="pull-right">
                        {renderStatus(orders[mainExamConfirmationGroup.order_id].status)}
                      </div>
                    )}
                  </span>
                }
                key={i + 1}
              >
                <table className="t-table -pticket">
                  <thead>
                    <th>วันที่</th>
                    <th>วิชา</th>
                    <th>สนามสอบกระดาษ</th>
                    <th>สนามสอบคอม</th>
                  </thead>
                  <tbody>
                    {examConfirmationGroup.confirmationsById.map((examConfirmation, i) => {
                      const previousConfirmation = _.find(previousConfirmationGroup.confirmationsById, {
                        day: examConfirmation.day,
                      });
                      const previousSubjects = _.get(previousConfirmation, `subjects`);
                      return (
                        <tr id={i}>
                          <td>
                            <b>วันที่ {examConfirmation.day}</b>
                          </td>
                          <td className="whitespace-nowrap">
                            {examConfirmation.subjects.map((subject, i) => {
                              let foundSubject = _.includes(previousSubjects, subject);
                              console.log(foundSubject);
                              return (
                                <div className={cn({ error: !foundSubject && !isFirstConfirmation })}>
                                  <b>{i + 1}.</b> {subject}
                                </div>
                              );
                            })}
                          </td>
                          <td>
                            <div>
                              {/* <div>
                                <i className="i-yes"></i> สนามสอบกระดาษ
                              </div> */}
                              {[1, 2, 3, 4, 5].map((i) => {
                                const siteCode = _.get(examConfirmation, `place_${i}`) || '-';
                                const previouSiteCode = _.get(previousConfirmation, `place_${i}`) || '-';

                                const siteLabel = _.get(sites, `${siteCode}`)
                                  ? `${_.get(sites, `${siteCode}.school_name`)} [${_.get(
                                      sites,
                                      `${siteCode}.province`
                                    )}]`
                                  : '-';
                                return (
                                  <div className={cn({ error: previouSiteCode !== siteCode && !isFirstConfirmation })}>
                                    อันดับที่ {i} {siteLabel}
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                          <td>
                            <div>
                              {/* <div>
                                <i className="i-yes"></i> สนามสอบคอมพิวเตอร์
                              </div> */}
                              {[1, 2, 3, 4, 5].map((i) => {
                                const siteCode = _.get(examConfirmation, `cbt_place_${i}`) || '-';
                                const previouSiteCode = _.get(previousConfirmation, `cbt_place_${i}`) || '-';
                                const siteLabel = _.get(sites, `${siteCode}`)
                                  ? `${_.get(sites, `${siteCode}.school_name`)} [${_.get(
                                      sites,
                                      `${siteCode}.province`
                                    )}]`
                                  : '-';
                                return (
                                  <div
                                    className={cn({ error: previouSiteCode !== siteCode && !isFirstConfirmation })}
                                    id={i}
                                  >
                                    อันดับที่ {i} {siteLabel}
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Panel>
            </Collapse>
            {isDiffSubject && i !== examConfirmations.length - 1 && <hr></hr>}
          </div>
        );
      })}
    </div>
  );
};

export default ExamRegisterLogs;
