module.exports = (() => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_STAGING
      ? {
          apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
          // apiHost: 'https://t66.tcbackup.com/try-another-api',
          pdfHost: 'https://tcas-staging-pdf-dot-tcas65.as.r.appspot.com/',
        }
      : {
          apiHost: 'https://admin-api-dot-tcas65.as.r.appspot.com',
          // apiHost: 'https://t66.tcbackup.com/try-another-api',
          pdfHost: 'https://tcas-pdf-dot-tcas65.as.r.appspot.com/',
        };
  }

  // Default config
  return {
    // apiHost: 'https://tcas65.as.r.appspot.com',
    apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
    // apiHost: 'https://admin-api-dot-tcas65.as.r.appspot.com',
    // apiHost: 'https://t66.tcbackup.com/try-another-api',
    // pdfHost: 'https://t66.tcbackup.com/try-another-api-pdf',
    // apiHost: 'http://localhost:8020',
    pdfHost: 'http://localhost:8022',
  };
})();
