import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import { Tabs, Collapse } from 'antd';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import cn from 'classnames';
import TH from 'constants/th';
const { Panel } = Collapse;
import EXAM_SUBJECTS from 'constants/exam-subjects';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';
import { applyMiddleware } from 'redux';
import numeral from 'numeral';
// import TICKET_CATEGORIES from 'constants/order-types.json';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
//   result[category.value] = category.label;
//   return result;
// }, {});

export default ({ order, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [sourceData, setSourceData] = useState();
  const [fullOrder, setFullOrder] = useState();
  const [reason, setReason] = useState('');
  const [admissions, setAdmissions] = useState([]);

  // img zoom

  useEffect(() => {
    const fetchFullOrder = async () => {
      try {
        setLoading(true);

        const response = await callApi({ url: `/orders/${order._id}` });

        setFullOrder(response.order);
        setAdmissions(response.admissions);
        setLoading(false);
        // fetchOrders(currentPage);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));
        setFullOrder(order);
        setLoading(false);
      }
    };
    if (order && order._id) {
      fetchFullOrder();
    }
  }, [order]);

  const approveOrder = async () => {
    try {
      setLoading(true);
      const _order = await callApi({
        url: `/orders/${order._id}/approve`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  const cancelOrder = async () => {
    try {
      setLoading(true);
      const _order = await callApi({
        url: `/orders/${order._id}/cancel`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  if (!fullOrder) {
    return null;
  }
  let title = `รายการสมัครรอบ 3 เลขที่ ${order.order_id}`;

  let footer = [];
  if (order.status === 'paid') {
    footer = [<div>รายการชำระเงินสำเร็จแล้ว</div>];
  } else if (order.status === 'cancel') {
    footer = [
      <Popconfirm
        onConfirm={() => {
          approveOrder();
        }}
        title={'คุณต้องการอนุมัติรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="primary" loading={loading}>
          อนุมัติรายการ
        </Button>
      </Popconfirm>,
    ];
  } else {
    footer = [
      // <Popconfirm
      //   onConfirm={() => {
      //     cancelOrder();
      //   }}
      //   title={'คุณต้องการยกเลิกรายการนี้ใช่หรือไม่'}
      //   okText="ใช่"
      //   cancelText="ไม่ใช่"
      // >
      //   <Button key="submit" type="danger" loading={loading} style={{ marginRight: 10 }}>
      //     ยกเลิกรายการ
      //   </Button>
      // </Popconfirm>,

      <Popconfirm
        onConfirm={() => {
          approveOrder();
        }}
        title={'คุณต้องการอนุมัติรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="primary" loading={loading}>
          อนุมัติรายการ
        </Button>
      </Popconfirm>,
    ];
  }

  const paidAdmission = _.find(admissions, { order_id: order.order_id }) || {};
  console.log(paidAdmission, order.order_id);
  const defaultActiveCollapse = _.findIndex(admissions, { order_id: order.order_id }) + 1 || 1;

  return (
    <Modal
      title={title}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={1200}
      footer={footer}
      onCancel={() => setIsVisible(false)}
    >
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>เลขที่รายการ</td>
                <td>{order.order_id} </td>
              </tr>
              <tr>
                <td>ชนิดรายการ</td>
                <td>สมัครคัดเลือกเข้าศึกษาต่อรอบ 3</td>
              </tr>
              <tr>
                <td>รหัสบัตรประชาชน</td>
                <td>{order.citizen_id}</td>
              </tr>
              <tr>
                <td>ชื่อ</td>
                <td>{`${get(order, `student.first_name`)} ${get(order, `student.last_name`)} `}</td>
              </tr>
              <tr>
                <td>มูลค่า</td>
                <td>{numeral(order.amount).format('0,0.00')}</td>
              </tr>
              <tr>
                <td>สร้างเมื่อวันที่</td>
                <td>{moment(order.created_at).add(543, 'years').format('D MMM YY HH:mm น.')}</td>
              </tr>
              <tr>
                <td>รายการสำหรับการสมัครครั้งที่</td>
                <td>{_.get(paidAdmission, 'apply_id')}</td>
              </tr>
              <tr>
                <td>รายการสำหรับการยืนยันครั้งที่</td>
                <td>{_.get(paidAdmission, 'confirm_id')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>รายละเอียดรายการ</h3>

        {admissions.map((admission, i) => {
          return (
            <Collapse ghost defaultActiveKey={[defaultActiveCollapse]}>
              <Panel
                header={
                  <span>
                    การสมัครครั้งที่ <b>{admission.apply_id}</b> / ยืนยันครั้งที่ <b>{admission.confirm_id}</b> เมื่อ{' '}
                    <b>{moment(admission.created_at).add(543, 'years').format('D MMM YY HH:mm น.')}</b>
                  </span>
                }
                key={i + 1}
              >
                <table className="t-table -pticket">
                  <thead>
                    <th>อันดับ</th>
                    <th>มหาวิทยาลัย</th>
                    <th>หลักสูตร</th>
                  </thead>
                  <tbody>
                    {admission.program_projects.map((_project, j) => {
                      const project = _project.program_project;
                      return (
                        <tr>
                          <td>
                            <b>{j + 1}</b>
                          </td>
                          <td>
                            <b>
                              {project.program.university_name_th} {project.program.campus_name_th}
                              <br />
                              {project.program.faculty_name_th}
                            </b>
                          </td>
                          <td>
                            <div>สาขา {project.program.field_name_th}</div>
                            {project.program.major_name_th && project.program.major_name_th !== '0' && (
                              <div>วิชาเอก{project.program.major_name_th}</div>
                            )}
                            <div>{project.program.program_name_th}</div>
                            {project.program.project_name_th && project.program.project_name_th !== '0' && (
                              <div>โครงการ{project.program.project_name_th}</div>
                            )}
                            {project.program.program_type_name_th && (
                              <div>({project.program.program_type_name_th})</div>
                            )}
                          </td>
                        </tr>
                      );
                    })}

                    {/* {fullOrder.examApplications.map((app) => {
              return (
                <tr>
                  <td>{EXAM_SUBJECTS.labels[app.subject_name]}</td>
                </tr>
              );
            })} */}
                  </tbody>
                </table>
              </Panel>
            </Collapse>
          );
        })}

        <div></div>
      </div>
    </Modal>
  );
};
