import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import { Tabs, Collapse } from 'antd';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import cn from 'classnames';
import TH from 'constants/th';
const { Panel } = Collapse;
import EXAM_SUBJECTS from 'constants/exam-subjects';
import ExamRegisterLogs from 'containers/Student/Detail/ExamRegisterLogs.js';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';
import { applyMiddleware } from 'redux';
import numeral from 'numeral';
// import TICKET_CATEGORIES from 'constants/order-types.json';

const Option = Select.Option;

moment.locale('th');

// const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
//   result[category.value] = category.label;
//   return result;
// }, {});

export default ({ order, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sourceData, setSourceData] = useState();
  const [fullOrder, setFullOrder] = useState();
  const [orderLogs, setOrderLogs] = useState({});
  const [reason, setReason] = useState('');
  // const subjects = useSelector((state) => state.subjects || []);
  // const sites = useSelector((state) => state.sites || []);

  const [examConfirmations, setExamConfirmations] = useState([]);

  // const fetchSubjects = async () => {
  //   if (subjects.length > 0) {
  //     return;
  //   }
  //   try {
  //     const { data } = await callApi({
  //       url: '/subjects',
  //     });
  //     dispatch({ type: 'UPDATE_SUBJECTS', data });
  //   } catch (error) {
  //     console.log('errror', error);
  //   }
  // };

  // const fetchSites = async () => {
  //   if (sites.length > 0) {
  //     return;
  //   }
  //   try {
  //     const _sites = await callApi({ url: '/exam-sites' });
  //     const sitesBySiteCode = _.keyBy(_sites, 'site_code');
  //     dispatch({ type: 'UPDATE_SITES', data: sitesBySiteCode });
  //   } catch (error) {
  //     console.log('errror', error);
  //   }

  //   // setSites(_sites.data);
  // };

  // img zoom

  useEffect(() => {
    const fetchFullOrder = async () => {
      try {
        setLoading(true);

        const response = await callApi({ url: `/orders/${order._id}` });

        const currentOrder = response.order;
        // _.find(response.order, { _id: order._id });
        const currentDay = _.get(currentOrder, 'apply_exams.applications[0].day');
        // console.log(currentOrder, response.order._id, order._id);
        const currentType = ['1', '2', '3'].indexOf(currentDay) > -1 ? 'TGAT-TPAT' : 'A-LEVEL';
        _.forEach(response.examConfirmations, (confirmation) => {
          const type = ['1', '2', '3'].indexOf(confirmation.day) > -1 ? 'TGAT-TPAT' : 'A-LEVEL';
          confirmation.type = type;
        });

        response.examConfirmations = response.examConfirmations.filter((cf) => cf.type === currentType);

        console.log(currentType);

        const confirmationGroupByTransaction = _.map(
          _.groupBy(response.examConfirmations, (confirmation) => {
            return `${confirmation.type}_${confirmation.apply_id}_${confirmation.site_confirm_id}`;
          }),
          (confirmationsById, id) => {
            return {
              id: id,
              created_at: _.get(confirmationsById, `[0].created_at`),
              confirmationsById: _.sortBy(confirmationsById, 'day'),
              order_id: _.get(confirmationsById, `[0].order_id`),
            };
          }
        );

        setFullOrder(response.order);
        setOrderLogs(_.keyBy(response.orderLogs, 'order_id'));
        setExamConfirmations(confirmationGroupByTransaction);
        setLoading(false);
        // fetchOrders(currentPage);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));
        setFullOrder(order);
        setLoading(false);
      }
    };
    if (order && order._id) {
      fetchFullOrder();
    }
  }, [order]);

  const approveOrder = async () => {
    try {
      setLoading(true);
      const _order = await callApi({
        url: `/orders/${order._id}/approve`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  const cancelOrder = async () => {
    try {
      setLoading(true);
      const _order = await callApi({
        url: `/orders/${order._id}/cancel`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  if (!fullOrder) {
    return null;
  }
  let title = `รายการสมัครรอบ 3 เลขที่ ${order.order_id}`;

  let footer = [];
  if (order.status === 'paid') {
    footer = [<div>รายการชำระเงินสำเร็จแล้ว</div>];
  } else if (order.status === 'cancel') {
    footer = [
      <Popconfirm
        onConfirm={() => {
          approveOrder();
        }}
        title={'คุณต้องการอนุมัติรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="primary" loading={loading}>
          อนุมัติรายการ
        </Button>
      </Popconfirm>,
    ];
  } else {
    footer = [
      // <Popconfirm
      //   onConfirm={() => {
      //     cancelOrder();
      //   }}
      //   title={'คุณต้องการยกเลิกรายการนี้ใช่หรือไม่'}
      //   okText="ใช่"
      //   cancelText="ไม่ใช่"
      // >
      //   <Button key="submit" type="danger" loading={loading} style={{ marginRight: 10 }}>
      //     ยกเลิกรายการ
      //   </Button>
      // </Popconfirm>,

      <Popconfirm
        onConfirm={() => {
          approveOrder();
        }}
        title={'คุณต้องการอนุมัติรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="primary" loading={loading}>
          อนุมัติรายการ
        </Button>
      </Popconfirm>,
    ];
  }

  // const paidExam = _.find(applyExams, { order_id: order.order_id }) || {};
  // console.log(paidExam, order.order_id);
  const defaultActiveCollapse = true;

  const applyExams = _.get(order, 'apply_exams.applications') || [];
  const applyExamsGroupByDay = _.groupBy(applyExams, 'day');

  const examConfirmationForCurrentOrder = _.find(examConfirmations, { order_id: order.order_id });
  const examConfirmationForCurrentOrderIndex = _.findIndex(examConfirmations, { order_id: order.order_id });

  return (
    <Modal
      title={title}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={1200}
      footer={footer}
      onCancel={() => setIsVisible(false)}
    >
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>เลขที่รายการ</td>
                <td>{order.order_id} </td>
              </tr>
              <tr>
                <td>ชนิดรายการ</td>
                <td>การสมัครสอบ</td>
              </tr>
              <tr>
                <td>รหัสบัตรประชาชน</td>
                <td>{order.citizen_id}</td>
              </tr>
              <tr>
                <td>ชื่อ</td>
                <td>{`${get(order, `student.first_name`)} ${get(order, `student.last_name`)} `}</td>
              </tr>
              <tr>
                <td>มูลค่า</td>
                <td>{numeral(order.amount).format('0,0.00')}</td>
              </tr>
              <tr>
                <td>สร้างเมื่อวันที่</td>
                <td>{moment(order.created_at).add(543, 'years').format('D MMM YY HH:mm น.')}</td>
              </tr>
              <tr>
                <td>รายการสำหรับการสมัครครั้งที่</td>
                <td>{_.get(examConfirmationForCurrentOrder, 'confirmationsById[0].apply_id')}</td>
              </tr>
              <tr>
                <td>แก้ไขสนามสอบครั้งที่</td>
                <td>{_.get(examConfirmationForCurrentOrder, 'confirmationsById[0].site_confirm_id') || '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>รายละเอียดรายการ</h3>

        <ExamRegisterLogs
          examConfirmations={examConfirmations}
          orders={orderLogs}
          defaultActiveKey={[examConfirmationForCurrentOrderIndex + 1]}
        ></ExamRegisterLogs>
      </div>
    </Modal>
  );
};
