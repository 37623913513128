import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

// Helpers
import callApi from 'helpers/callapi';
import { download } from 'helpers/download';

// Component
import RoundStatus from './RoundStatus';
import Round from 'containers/Rounds/index';
import { Spin } from 'antd';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import VideoModal from 'components/VideoModal';

moment.locale('th');

const allRounds = [
  { name: 'Portfolio', index: 1 },
  { name: 'Quota', index: 2 },
  { name: 'Admission', index: 3 },
  { name: 'Direct Admission', index: 4 },
  { name: 'ผู้พิการ', index: 5 },
];

export default () => {
  const { university, setUniversity } = useMyUniversity();
  const [hasAnnouncement, setHasAnnouncement] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!university) return null;

  const downloadFile = async (subject) => {
    try {
      setLoading(true);

      const { link } = await callApi({
        url: '/program-projects/staff/exports',
        method: 'POST',
      });
      if (link) {
        download(link);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  return (
    <div className="t-main">
      <h1>ภาพรวม</h1>
      <div className="sec-intro">
        <div className="info">
          <p>
            สถานะของเอกสารและกำหนดการต่างๆ ของสถาบัน
            <br />
            สามารถเพิ่มข้อมูลการรับสมัครได้ในแบบฟอร์มด้านล่าง
            <br /> ส่วนข้อมูลหลักสูตร ให้คลิกที่เมนูด้านซ้ายมือ
          </p>
          <div className="_space s-flex _heading">
            <i className="i-none"></i> &nbsp; ไม่บังคับกรอก &nbsp;&nbsp;
            <i className="i-no"></i> &nbsp; บังคับกรอก ยังไม่ได้ใส่ข้อมูล &nbsp;&nbsp;
            <i className="i-yes"></i> &nbsp; ใส่ข้อมูลเรียบร้อยแล้ว
          </div>
        </div>
        <div className="help">
          <Link to="/help">
            <i className="i-manual"></i>คู่มือการใช้งาน
          </Link>
        </div>
      </div>

      {hasAnnouncement && (
        <div className="sec-announce">
          <h2>ประกาศ</h2>
          ข้อความประกาศ รวมถึงคำเตือนกรณียังส่งข้อมูลไม่ครบ
        </div>
      )}

      <VideoModal isVisible={isVideoModalVisible} vimeoId={'642471244'} setIsVisible={setIsVideoModalVisible} />

      <div className="sec-university-info">
        {allRounds.map((round, index) => (
          <RoundStatus key={index} round={round} university={university} />
        ))}
      </div>
      <hr className="hr-full" />
      <Round university={university} setUniversity={setUniversity} />

      {university.round3_enrollment_count > 0 && (
        <Spin spinning={loading}>
          <section>
            <h1>ข้อมูลการรับสมัครรอบ 3</h1>

            <ul className="sec-admissions">
              <li key={3} className={`round-3`}>
                <div>
                  <span>จำนวนการสมัครรอบ 3</span>
                  <h1 className="">{university.round3_enrollment_count} อันดับ</h1>
                </div>

                <div className="file">
                  <a onClick={() => downloadFile()}>
                    <i className="i-book"></i>
                    ข้อมูลการสมัครแยกตามโครงการที่เปิดรับ
                  </a>
                </div>
              </li>
            </ul>
          </section>
        </Spin>
      )}
    </div>
  );
};
