import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import { Tabs, Collapse } from 'antd';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import cn from 'classnames';
import TH from 'constants/th';
const { Panel } = Collapse;
import EXAM_SUBJECTS from 'constants/exam-subjects';
import AnswerTable from './AnswerTable';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';
import { applyMiddleware } from 'redux';
// import TICKET_CATEGORIES from 'constants/examApplication-types.json';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
//   result[category.value] = category.label;
//   return result;
// }, {});

const programCodeName = {
  1: 'หลักสูตรแกนกลาง (สามัญ)',
  2: 'หลักสูตรนานาชาติ',
  3: 'หลักสูตรอาชีวะ',
  4: 'หลักสูตรตามอัธยาศัย (กศน.)',
  5: 'หลักสูตรนานาชาติจบจากต่างประเทศ',
  6: 'สอบเทียบ GED',
};

const schoolHiddenFields = [
  'school_code',
  'school_zipcode',
  'school_province_id',
  'school_province_name',
  'school_amph_id',
  'school_amph_name',
  'school_tmbl_id',
  'school_tmbl_name',
];

export default ({ examApplication, setIsVisible, onClose, isVisible, notShowButton }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [sourceData, setSourceData] = useState();

  const subjects = useSelector((state) => state.subjects);

  const subjectLabels = subjects.reduce((results, subject) => {
    results[subject.key] = subject.label; //.replace('เกรดเฉลี่ยกลุ่มสาระฯ', 'เกรด ').replace('และเทคโนโลยี', '');

    return results;
  }, {});

  const [updatedFields, setUpdatedFields] = useState({});
  const [reason, setReason] = useState('');
  const [answers, setAnswers] = useState([]);

  const [fullApp, setFullApp] = useState();

  // img zoom
  const [isZoom, setZoom] = useState(false);
  const handleZoom = () => {
    setZoom(!isZoom);
  };

  useEffect(() => {
    const fetchFullApp = async () => {
      try {
        setLoading(true);

        const _app = await callApi({ url: `/exams/${examApplication._id}` });

        setFullApp(_app);
        setLoading(false);
        if (_app.answerUrl) {
          let _answers = await fetch(_app.answerUrl);
          _answers = await _answers.json();
          setAnswers(_answers);
        }
        // fetchOrders(currentPage);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));
        setFullApp(examApplication);
        setLoading(false);
      }
    };
    if (examApplication && examApplication._id) {
      fetchFullApp();
    }
  }, [examApplication]);

  const updateAck = async () => {
    try {
      setLoading(true);
      const _app = await callApi({
        url: `/exams/${examApplication._id}/ack`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  if (!examApplication) {
    return null;
  }
  let title = EXAM_SUBJECTS.labels[examApplication.subject_name];

  const getRequestType = (key) => {
    if (key === 'wrong_score') {
      return 'คะแนนผิด';
    }

    const splitKeys = key.split('_');
    const item = splitKeys[splitKeys.length - 1];
    const subCats = ['tgat1', 'tgat2', 'tgat3', 'tpat21', 'tpat22', 'tpat23'];
    for (let i = 0; i < subCats.length; i++) {
      const subSubject = subCats[i];
      if (key.indexOf(subSubject) > -1) {
        return `${subSubject.toUpperCase()} ข้อที่ ${item}`;
      }
    }

    return `ข้อที่ ${item}`;
  };

  if (!fullApp) {
    return <></>;
  }

  let footer = examApplication.acknowledged
    ? [<div>คำร้องได้รับการดำเนินการแล้ว</div>]
    : [
        <Button key="submit" type="primary" loading={loading} onClick={() => updateAck()}>
          รับเรื่อง
        </Button>,
      ];
  if (notShowButton) {
    footer = [];
  }
  console.log(answers);

  return (
    <Modal
      title={title}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={1200}
      footer={footer}
      onCancel={() => setIsVisible(false)}
    >
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>เลขที่นั่ง</td>
                <td>{examApplication.app_id} </td>
              </tr>
              <tr>
                <td>รหัสบัตรประชาชน</td>
                <td>{examApplication.citizen_id}</td>
              </tr>
              <tr>
                <td>ชื่อ</td>
                <td>{`${get(examApplication, `student.first_name`)} ${get(examApplication, `student.last_name`)} `}</td>
              </tr>
              <tr>
                <td>รูปแบบการสอบ</td>
                <td>{`${get(examApplication, `selected_exam_type`)}`}</td>
              </tr>
              <tr>
                <td>คะแนน</td>

                <td>{`${get(examApplication, `student.${EXAM_SUBJECTS.scores[examApplication.subject_name]}`) || 0} / ${
                  EXAM_SUBJECTS.maxScore[examApplication.subject_name]
                }`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {fullApp.recheck_requests.length > 0 && (
          <table className="t-table -pticket">
            <thead>
              <th>ข้อ</th>
              <th>คำอธิบาย</th>
            </thead>
            <tbody>
              {fullApp.recheck_requests.map((request, i) => {
                return (
                  <tr key={i}>
                    <td>{getRequestType(request.key)}</td>
                    <td>{request.text}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {}

        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Collapse defaultActiveKey={fullApp.selected_exam_type === 'cbt' ? ['0'] : ['1']}>
              <Panel
                header={
                  <div>
                    <div>กระดาษคำตอบ</div>
                  </div>
                }
                key="1"
              >
                <ul className="r-history">
                  <li>
                    <div className="xm-box">
                      <ul>
                        {fullApp &&
                          fullApp.keys &&
                          fullApp.keys.map((url, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="key"
                                  style={{ width: '49%', marginRight: '2%', display: 'inline-block' }}
                                >
                                  <img src={_.get(fullApp, `sheet_path_${index + 1}`)}></img>
                                </div>
                                <div className="student-answer" style={{ width: '49%', display: 'inline-block' }}>
                                  <img src={url}></img>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <br></br>
                  </li>
                </ul>
              </Panel>
            </Collapse>
          </Col>
        </Row>

        <Row gutter={[24, 16]}>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
          <Col span={24}>
            <Collapse defaultActiveKey={['0']}>
              <Panel
                header={
                  <div>
                    <div>ตารางคำตอบ</div>
                  </div>
                }
                key="1"
              >
                <AnswerTable answers={answers}></AnswerTable>
              </Panel>
            </Collapse>
          </Col>
        </Row>

        <div></div>
      </div>
    </Modal>
  );
};
