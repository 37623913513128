import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import EXAM_SUBJECTS from 'constants/exam-subjects';
import EXAM_SITES from 'constants/exam-sites';
import ExamRegisterLogs from 'containers/Student/Detail/ExamRegisterLogs.js';
import { message } from 'antd';
import { get } from 'lodash';

const { Panel } = Collapse;

const ExamRegister = ({ student }) => {
  // let { studentId } = useParams();
  const [orderLogs, setOrderLogs] = useState({});
  const [examConfirmations, setExamConfirmations] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchSites = async () => {
    if (sites.length > 0) {
      return;
    }
    try {
      const _sites = await callApi({ url: '/exam-sites' });
      const sitesBySiteCode = _.keyBy(_sites, 'site_code');
      dispatch({ type: 'UPDATE_SITES', data: sitesBySiteCode });
    } catch (error) {
      console.log('errror', error);
    }

    // setSites(_sites.data);
  };

  useEffect(() => {
    const fetchFullOrder = async () => {
      try {
        setLoading(true);

        const response = await callApi({ url: `/students/${student.citizen_id}/exam-confirmations` });

        _.forEach(response.examConfirmations, (confirmation) => {
          const type = ['1', '2', '3'].indexOf(confirmation.day) > -1 ? 'TGAT-TPAT' : 'A-LEVEL';
          confirmation.type = type;
        });

        const confirmationGroupByTransaction = _.map(
          _.groupBy(response.examConfirmations, (confirmation) => {
            const type = ['1', '2', '3'].indexOf(confirmation.day) > -1 ? 'TGAT-TPAT' : 'A-LEVEL';
            confirmation.type = type;

            return `${type}_${confirmation.apply_id}_${confirmation.site_confirm_id}`;
          }),
          (confirmationsById, id) => {
            return {
              id: id,
              created_at: _.get(confirmationsById, `[0].created_at`),
              confirmationsById: _.sortBy(confirmationsById, 'day'),
              order_id: _.get(confirmationsById, `[0].order_id`),
            };
          }
        );

        setOrderLogs(_.keyBy(response.orderLogs, 'order_id'));
        setExamConfirmations(confirmationGroupByTransaction);
        setLoading(false);
        setIsLoaded(true);
        // fetchOrders(currentPage);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));

        setLoading(false);
        setIsLoaded(true);
      }
    };
    if (!isLoaded) {
      fetchFullOrder();
    }
  }, [student]);
  if (!examConfirmations) {
    return <div></div>;
  }

  // const repliesByRound = _.groupBy(replies, 'type');

  // console.log(repliesByRound);

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>

      <h3>การสมัครสอบ</h3>

      <ExamRegisterLogs examConfirmations={examConfirmations} isGhost={false} orders={orderLogs}></ExamRegisterLogs>
    </div>
  );
};
export default ExamRegister;
