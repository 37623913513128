import React from 'react';

const Contact = () => {
  return (
    <div className="t-main">
      <h1>คู่มือการใช้งาน</h1>
      <div className="sec-box">
        <p>สามารถดูรายละเอียดและค้นหาข้อมูลการใช้งานได้ที่</p>
        <h3 className="_space">
          <strong>
            <a href="https://admin.help.t66internal.com/" target="_blank">
              admin.help.t66internal.com →
            </a>
          </strong>
        </h3>
        <p>โดยใช้ข้อมูลดังนี้</p>
        <code>Username: admin</code>
        <br />
        <code>Password: tcas66admin</code>
      </div>
    </div>
  );
};

export default Contact;
