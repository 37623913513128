const SENIOR_EDIT_FIELD = [
  { label: 'เบอร์โทรศัพท์', key: 'telephone' },
  { label: 'อีเมล', key: 'email', required: true },
  { label: 'gpax5', key: 'gpax5_score', type: 'number', required: true },
  { label: 'gpax6', key: 'gpax6_score', type: 'number' },
];

const PROFILE_FIELDS = [
  { label: 'คำนำหน้าชื่อ', key: 'title', noShow: true },
  { label: 'ชื่อ', key: 'first_name', noShow: true },
  { label: 'นามสกุล', key: 'last_name', noShow: true },
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'ชื่อ(EN)', key: 'first_name_additional' },
  { label: 'นามสกุล(EN)', key: 'last_name_additional' },

  { label: 'หมายเลขประจำตัว', key: 'citizen_id', required: true },
  { label: 'เบอร์โทรศัพท์', key: 'telephone' },
  { label: 'อีเมล', key: 'email' },
  { label: 'น้ำหนัก', key: 'weight' },
  { label: 'ส่วนสูง', key: 'height' },

  { label: 'วันเกิด', key: 'birth_date' },

  { label: 'ชื่อ - นามสกุลผู้ปกครอง', key: 'parent_name' },
  { label: 'เบอร์โทรศัพท์ผู้ปกครอง', key: 'parent_telephone' },
  { label: 'อีเมลผู้ปกครอง', key: 'parent_email' },

  { label: 'ชื่อบิดา', key: 'father_name' },
  { label: 'เบอร์โทรศัพท์บิดา', key: 'father_telephone' },
  { label: 'ชื่อมารดา', key: 'mother_name' },
  { label: 'เบอร์โทรศัพท์มารดา', key: 'mother_telephone' },
];

const ADDRESS_FIELDS = [
  { label: 'รหัสจังหวัด', key: 'province_id', noShow: true },
  { label: 'รหัสอำเภอ', key: 'amph_id', noShow: true },
  { label: 'รหัสตำบล', key: 'tmbl_id', noShow: true },
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'ที่อยู่', key: 'address' },
  { label: 'ตำบล', key: 'tmbl_name' },
  { label: 'อำเภอ', key: 'amph_name' },
  { label: 'จังหวัด', key: 'province_name' },

  { label: 'รหัสไปรษณีย์', key: 'zipcode' },
];

const SCHOOL_FIELDS = [
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'ปีการศึกษา', key: 'school_year' },
  { label: 'รหัสโรงเรียน', key: 'school_code' },
  { label: 'ชื่อโรงเรียน', key: 'school_name' },
  { label: 'โปรแกรมที่เรียน', key: 'program_code' },
  { label: 'จังหวัดโรงเรียน', key: 'school_province_name' },
  { label: 'ที่อยู่โรงเรียน(ต่างประเทศ)', key: 'school_address', isForeign: true },
  { label: 'เมือง', key: 'school_country_state', isForeign: true },
  { label: 'ประเทศ', key: 'school_country_name', isForeign: true },
  { label: 'รหัสไปรษณีย์', key: 'school_zipcode', isForeign: true },
];

const GPA_FIELDS = [
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'gpax5', key: 'gpax5_score', required: true },
  { label: 'gpax6', key: 'gpax6_score' },
  { label: 'gpa21', key: 'gpa21' },
  { label: 'gpa22', key: 'gpa22' },
  { label: 'gpa23', key: 'gpa23' },
  { label: 'gpa24', key: 'gpa24' },
  { label: 'gpa25', key: 'gpa25' },
  { label: 'gpa26', key: 'gpa26' },
  { label: 'gpa27', key: 'gpa27' },
  { label: 'gpa28', key: 'gpa28' },
  { label: 'gpa29', key: 'gpa29' },
  { label: 'credit gpa21', key: 'credit_gpa21' },
  { label: 'credit gpa22', key: 'credit_gpa22' },
  { label: 'credit gpa23', key: 'credit_gpa23' },
  { label: 'credit gpa24', key: 'credit_gpa24' },
  { label: 'credit gpa25', key: 'credit_gpa25' },
  { label: 'credit gpa26', key: 'credit_gpa26' },
  { label: 'credit gpa27', key: 'credit_gpa27' },
  { label: 'credit gpa28', key: 'credit_gpa28' },
  { label: 'credit gpa29', key: 'credit_gpa29' },
];

const SUB9 = [
  { label: 'วิชาสามัญ ภาษาไทย', key: '9sub_09' },
  { label: 'วิชาสามัญ สังคมศึกษา', key: '9sub_19' },
  { label: 'วิชาสามัญ ภาษาอังกฤษ', key: '9sub_29' },
  { label: 'วิชาสามัญ คณิตศาสตร์', key: '9sub_39' },
  { label: 'วิชาสามัญ ฟิสิกส์', key: '9sub_49' },
  { label: 'วิชาสามัญ เคมี', key: '9sub_59' },
  { label: 'วิชาสามัญ ชีววิทยา', key: '9sub_69' },
  { label: 'วิชาสามัญ คณิตศาสตร์', key: '9sub_89' },
  { label: 'วิชาสามัญ วิทยาศาสตร์ทั่วไป', key: '9sub_99' },

  { label: 'วิชาสามัญ ภาษาไทย T-Score', key: '9sub_09_tscore' },
  { label: 'วิชาสามัญ สังคมศึกษา T-Score', key: '9sub_19_tscore' },
  { label: 'วิชาสามัญ ภาษาอังกฤษ T-Score', key: '9sub_29_tscore' },
  { label: 'วิชาสามัญ คณิตศาสตร์ T-Score', key: '9sub_39_tscore' },
  { label: 'วิชาสามัญ ฟิสิกส์ T-Score', key: '9sub_49_tscore' },
  { label: 'วิชาสามัญ เคมี T-Score', key: '9sub_59_tscore' },
  { label: 'วิชาสามัญ ชีววิทยา T-Score', key: '9sub_69_tscore' },
  { label: 'วิชาสามัญ คณิตศาสตร์ T-Score', key: '9sub_89_tscore' },
  { label: 'วิชาสามัญ วิทยาศาสตร์ทั่วไป T-Score', key: '9sub_99_tscore' },
];

const GATPAT = [
  { label: 'GAT1 ความสามารถในการอ่าน/การเขียน/การคิดเชิงวิเคราะห์/และการแก้โจทย์ปัญหา', key: 'gat1' },
  { label: 'GAT2 ความสามารถในการสื่อสารด้วยภาษาอังกฤษ', key: 'gat2' },
  { label: 'PAT1 ความถนัดทางคณิตศาสตร์ ', key: 'pat1' },
  { label: 'PAT2 ความถนัดทางวิทยาศาสตร์', key: 'pat2' },
  { label: 'PAT3 ความถนัดทางวิศวกรรมศาสตร์', key: 'pat3' },
  { label: 'PAT4 ความถนัดทางสถาปัตยกรรมศาสตร์', key: 'pat4' },
  { label: 'PAT5 ความถนัดทางวิชาชีพครู', key: 'pat5' },
  { label: 'PAT6 ความถนัดทางศิลปกรรมศาสตร์', key: 'pat6' },
  //   { label: 'PAT7 ความถนัดทางภาษา', key: 'pat7' },
  { label: 'PAT7-1 ความถนัดทางภาษาฝรั่งเศส', key: 'pat7_1' },
  { label: 'PAT7-2 ความถนัดทางภาษาเยอรมัน', key: 'pat7_2' },
  { label: 'PAT7-3 ความถนัดทางภาษาญี่ปุ่น', key: 'pat7_3' },
  { label: 'PAT7-4 ความถนัดทางภาษาจีน', key: 'pat7_4' },
  { label: 'PAT7-5 ความถนัดทางภาษาอาหรับ', key: 'pat7_5' },
  { label: 'PAT7-6 ความถนัดทางภาษาบาลี', key: 'pat7_6' },
  { label: 'PAT7-7 ความถนัดทางภาษาเกาหลี', key: 'pat7_7' },

  { label: 'GAT T-Score', key: 'gat_tscore' },
  { label: 'GAT1 T-Score', key: 'gat1_tscore' },
  { label: 'GAT2 T-Score', key: 'gat2_tscore' },
  { label: 'PAT1 T-Score', key: 'pat1_tscore' },
  { label: 'PAT2 T-Score', key: 'pat2_tscore' },
  { label: 'PAT3 T-Score', key: 'pat3_tscore' },
  { label: 'PAT4 T-Score', key: 'pat4_tscore' },
  { label: 'PAT5 T-Score', key: 'pat5_tscore' },
  { label: 'PAT6 T-Score', key: 'pat6_tscore' },
  //   { label: 'PAT7 T-Score', key: 'pat7_tscore' },
  { label: 'PAT7-1 T-Score', key: 'pat7_1_tscore' },
  { label: 'PAT7-2 T-Score', key: 'pat7_2_tscore' },
  { label: 'PAT7-3 T-Score', key: 'pat7_3_tscore' },
  { label: 'PAT7-4 T-Score', key: 'pat7_4_tscore' },
  { label: 'PAT7-5 T-Score', key: 'pat7_5_tscore' },
  { label: 'PAT7-6 T-Score', key: 'pat7_6_tscore' },
  { label: 'PAT7-7 T-Score', key: 'pat7_7_tscore' },
];

const TGATTPAT = [
  { key: 'tgat', label: 'ความถนัดทั่วไป (TGAT)' },
  { key: 'tgat1', label: 'การสื่อสารภาษาอังกฤษ (TGAT1)' },
  { key: 'tgat2', label: 'การคิดอย่างมีเหตุผล (TGAT2)' },
  { key: 'tgat3', label: 'สมรรถนะการทำงาน (TGAT3)' },

  { key: 'tpat2', label: 'ความถนัดศิลปกรรมศาสตร์ (TPAT2)' },
  { key: 'tpat21', label: 'ทัศนศิลป์ (TPAT21)' },
  { key: 'tpat22', label: 'ดนตรี (TPAT22)' },
  { key: 'tpat23', label: 'นาฏศิลป์ (TPAT23)' },
  { key: 'tpat3', label: 'ความถนัดวิทยาศาสตร์ เทคโนโลยี วิศวกรรมศาสตร์ (TPAT3)' },
  { key: 'tpat4', label: 'ความถนัดสถาปัตยกรรมศาสตร์ (TPAT4)' },
  { key: 'tpat5', label: 'ความถนัดครุศาสตร์-ศึกษาศาสตร์ (TPAT5)' },

  { key: 'tgat_tscore', label: 'TGAT T-Score' },
  { key: 'tgat1_tscore', label: 'TGAT1 T-Score' },
  { key: 'tgat2_tscore', label: 'TGAT2 T-Score' },
  { key: 'tgat3_tscore', label: 'TGAT3 T-Score' },
  { key: 'tpat2_tscore', label: 'TPAT2 T-Score' },
  { key: 'tpat21_tscore', label: 'TPAT21 T-Score' },
  { key: 'tpat22_tscore', label: 'TPAT22 T-Score' },
  { key: 'tpat23_tscore', label: 'TPAT23 T-Score' },
  { key: 'tpat3_tscore', label: 'TPAT3 T-Score' },
  { key: 'tpat4_tscore', label: 'TPAT4 T-Score' },
  { key: 'tpat5_tscore', label: 'TPAT5 T-Score' },

  { key: 'tgat_ps', label: 'TGAT Percentile' },
  { key: 'tgat1_ps', label: 'TGAT1 Percentile' },
  { key: 'tgat2_ps', label: 'TGAT2 Percentile' },
  { key: 'tgat3_ps', label: 'TGAT3 Percentile' },
  { key: 'tpat2_ps', label: 'TPAT2 Percentile' },
  { key: 'tpat21_ps', label: 'TPAT21 Percentile' },
  { key: 'tpat22_ps', label: 'TPAT22 Percentile' },
  { key: 'tpat23_ps', label: 'TPAT23 Percentile' },
  { key: 'tpat3_ps', label: 'TPAT3 Percentile' },
  { key: 'tpat4_ps', label: 'TPAT4 Percentile' },
  { key: 'tpat5_ps', label: 'TPAT5 Percentile' },
];

const ALEVEL = [
  { key: 'a_lv_61', label: 'A-Level คณิตศาสตร์ประยุกต์ 1 (พื้นฐาน+เพิ่มเติม)' },
  { key: 'a_lv_62', label: 'A-Level คณิตศาสตร์ประยุกต์ 2 (พื้นฐาน)' },
  { key: 'a_lv_63', label: 'A-Level วิทยาศาสตร์ประยุกต์' },
  { key: 'a_lv_64', label: 'A-Level ฟิสิกส์' },
  { key: 'a_lv_65', label: 'A-Level เคมี' },
  { key: 'a_lv_66', label: 'A-Level ชีววิทยา' },
  { key: 'a_lv_70', label: 'A-Level สังคมศึกษา' },
  { key: 'a_lv_81', label: 'A-Level ภาษาไทย' },
  { key: 'a_lv_82', label: 'A-Level ภาษาอังกฤษ' },
  { key: 'a_lv_83', label: 'A-Level ภาษาฝรั่งเศส' },
  { key: 'a_lv_84', label: 'A-Level ภาษาเยอรมัน' },
  { key: 'a_lv_85', label: 'A-Level ภาษาญี่ปุ่น' },
  { key: 'a_lv_86', label: 'A-Level ภาษาเกาหลี' },
  { key: 'a_lv_87', label: 'A-Level ภาษาจีน' },
  { key: 'a_lv_88', label: 'A-Level ภาษาบาลี' },
  { key: 'a_lv_89', label: 'A-Level ภาษาสเปน' },

  { key: 'a_lv_61_tscore', label: 'a_lv_61 T-SCORE' },
  { key: 'a_lv_62_tscore', label: 'a_lv_62 T-SCORE' },
  { key: 'a_lv_63_tscore', label: 'a_lv_63 T-SCORE' },
  { key: 'a_lv_64_tscore', label: 'a_lv_64 T-SCORE' },
  { key: 'a_lv_65_tscore', label: 'a_lv_65 T-SCORE' },
  { key: 'a_lv_66_tscore', label: 'a_lv_66 T-SCORE' },
  { key: 'a_lv_70_tscore', label: 'a_lv_70 T-SCORE' },
  { key: 'a_lv_81_tscore', label: 'a_lv_81 T-SCORE' },
  { key: 'a_lv_82_tscore', label: 'a_lv_82 T-SCORE' },
  { key: 'a_lv_83_tscore', label: 'a_lv_83 T-SCORE' },
  { key: 'a_lv_84_tscore', label: 'a_lv_84 T-SCORE' },
  { key: 'a_lv_85_tscore', label: 'a_lv_85 T-SCORE' },
  { key: 'a_lv_86_tscore', label: 'a_lv_86 T-SCORE' },
  { key: 'a_lv_87_tscore', label: 'a_lv_87 T-SCORE' },
  { key: 'a_lv_88_tscore', label: 'a_lv_88 T-SCORE' },
  { key: 'a_lv_89_tscore', label: 'a_lv_89 T-SCORE' },

  { key: 'a_lv_61_ps', label: 'a_lv_61 Percentile' },
  { key: 'a_lv_62_ps', label: 'a_lv_62 Percentile' },
  { key: 'a_lv_63_ps', label: 'a_lv_63 Percentile' },
  { key: 'a_lv_64_ps', label: 'a_lv_64 Percentile' },
  { key: 'a_lv_65_ps', label: 'a_lv_65 Percentile' },
  { key: 'a_lv_66_ps', label: 'a_lv_66 Percentile' },
  { key: 'a_lv_70_ps', label: 'a_lv_70 Percentile' },
  { key: 'a_lv_81_ps', label: 'a_lv_81 Percentile' },
  { key: 'a_lv_82_ps', label: 'a_lv_82 Percentile' },
  { key: 'a_lv_83_ps', label: 'a_lv_83 Percentile' },
  { key: 'a_lv_84_ps', label: 'a_lv_84 Percentile' },
  { key: 'a_lv_85_ps', label: 'a_lv_85 Percentile' },
  { key: 'a_lv_86_ps', label: 'a_lv_86 Percentile' },
  { key: 'a_lv_87_ps', label: 'a_lv_87 Percentile' },
  { key: 'a_lv_88_ps', label: 'a_lv_88 Percentile' },
  { key: 'a_lv_89_ps', label: 'a_lv_89 Percentile' },
];

const OTHERS_SUBJECTS = [
  { label: 'วิชาเฉพาะกสพทฉบับที่ 1', key: 'cotmes_01' },
  { label: 'วิชาเฉพาะกสพทฉบับที่ 2', key: 'cotmes_02' },
  { label: 'วิชาเฉพาะกสพทฉบับที่ 3', key: 'cotmes_03' },
];

module.exports = {
  SENIOR_EDIT_FIELD,
  PROFILE_FIELDS,
  ADDRESS_FIELDS,
  SCHOOL_FIELDS,
  GPA_FIELDS,

  TGATTPAT,
  ALEVEL,
  GATPAT,
  SUB9,

  OTHERS_SUBJECTS,
};
