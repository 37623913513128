import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import { Tabs, Collapse } from 'antd';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import cn from 'classnames';
import TH from 'constants/th';
const { Panel } = Collapse;
import EXAM_SUBJECTS from 'constants/exam-subjects';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';
import { applyMiddleware } from 'redux';
import numeral from 'numeral';
// import TICKET_CATEGORIES from 'constants/order-types.json';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
//   result[category.value] = category.label;
//   return result;
// }, {});

const ORDER_TYPES = [
  { label: 'ทั้งหมด', value: 'all' },
  // { label: 'การสมัครสอบ', value: 'pending' },
  { label: 'ตรวจผลสอบ', value: 'recheck_exam' },
  { label: 'สมัครเรียน', value: 'apply_round3' },
];
export default ({ order, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [sourceData, setSourceData] = useState();
  const [fullOrder, setFullOrder] = useState();
  const [reason, setReason] = useState('');

  // img zoom
  const [isZoom, setZoom] = useState(false);
  const handleZoom = () => {
    setZoom(!isZoom);
  };

  useEffect(() => {
    const fetchFullOrder = async () => {
      try {
        setLoading(true);

        const _order = await callApi({ url: `/orders/${order._id}` });

        setFullOrder(_order);
        setLoading(false);
        // fetchOrders(currentPage);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));
        setFullOrder(order);
        setLoading(false);
      }
    };
    if (order && order._id) {
      fetchFullOrder();
    }
  }, [order]);

  const approveOrder = async () => {
    try {
      setLoading(true);
      const _order = await callApi({
        url: `/orders/${order._id}/approve`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  const cancelOrder = async () => {
    try {
      setLoading(true);
      const _order = await callApi({
        url: `/orders/${order._id}/cancel`,
        method: 'POST',
        body: {
          reason,
        },
      });
      setLoading(false);
      onClose();
    } catch (err) {
      console.error('Error while trying to fetch orders.', err);
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  if (!fullOrder) {
    return null;
  }
  let title = `การขอตรวจสอบกระดาษคำตอบ เลขที่ ${order.order_id}`;

  const orderType = find(ORDER_TYPES, { value: order.order_type });

  let footer = [];
  if (order.status === 'paid') {
    footer = [<div>รายการชำระเงินสำเร็จแล้ว</div>];
  } else if (order.status === 'cancel') {
    footer = [
      <Popconfirm
        onConfirm={() => {
          approveOrder();
        }}
        title={'คุณต้องการอนุมัติรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="primary" loading={loading}>
          อนุมัติรายการ
        </Button>
      </Popconfirm>,
    ];
  } else {
    footer = [
      <Popconfirm
        onConfirm={() => {
          cancelOrder();
        }}
        title={'คุณต้องการยกเลิกรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="danger" loading={loading} style={{ marginRight: 10 }}>
          ยกเลิกรายการ
        </Button>
      </Popconfirm>,

      <Popconfirm
        onConfirm={() => {
          approveOrder();
        }}
        title={'คุณต้องการอนุมัติรายการนี้ใช่หรือไม่'}
        okText="ใช่"
        cancelText="ไม่ใช่"
      >
        <Button key="submit" type="primary" loading={loading}>
          อนุมัติรายการ
        </Button>
      </Popconfirm>,
    ];
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={1200}
      footer={footer}
      onCancel={() => setIsVisible(false)}
    >
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>เลขที่รายการ</td>
                <td>{order.order_id} </td>
              </tr>
              <tr>
                <td>ชนิดรายการ</td>
                <td>{orderType && orderType.label}</td>
              </tr>
              <tr>
                <td>รหัสบัตรประชาชน</td>
                <td>{order.citizen_id}</td>
              </tr>
              <tr>
                <td>ชื่อ</td>
                <td>{`${get(order, `student.first_name`)} ${get(order, `student.last_name`)} `}</td>
              </tr>
              <tr>
                <td>มูลค่า</td>
                <td>{numeral(order.amount).format('0,0.00')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>รายละเอียดรายการ</h3>
        <table className="t-table -pticket">
          <thead>
            <th>วิชาที่ทำการขอตรวจข้อมูล</th>
          </thead>
          <tbody>
            {fullOrder.examApplications.map((app) => {
              return (
                <tr>
                  <td>{EXAM_SUBJECTS.labels_66[app.subject_name]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div></div>
      </div>
    </Modal>
  );
};
