import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';
import ImportModal from './ImportModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

const CASES = [
  { label: 'ตัดสิทธิ์แพทยศาสตร์', value: '1' },
  { label: 'ตัดสิทธิ์ทันตแพทยศาสตร์', value: '2' },
  { label: 'ตัดสิทธิ์เภสัชศาสตร์', value: '3' },
  { label: 'ตัดสิทธิ์สัตวแพทยศาสตร์', value: '4' },
  { label: 'ติดแพทย์สำรอง', value: '5' },
  { label: 'ทุน 100%', value: '6' },
];

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const schools = useSelector((state) => {
    return state.schools || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);
  const universities = useSelector((state) => state.universities || []);
  const [university_id, setUniverysityId] = useState('');
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [programProjects, setProgramProjects] = useState([]);
  const [selectedEnrollmentOffer, setSelectedEnrollmentOffer] = useState();
  const [importModalVisible, setImportModalVisible] = useState(false);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    case: 'all',
  });

  const fetchUniversities = async () => {
    if (universities.length > 0) {
      return;
    }
    try {
      const result = await callApi({
        url: '/configs/universities',
      });
      dispatch({ type: 'UPDATE_UNIVERSITIES', data: result });
    } catch (error) {
      console.log('errror', error);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchDisqualifiedStudents = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: students } = await callApi({
        url: '/enrollment-offers/disqualify',
        query,
      });
      setStudents(students || []);
      setStudentCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setStudents([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      let university = me.university_id || '999';
      if (me.role === 'super') {
        university = 'all';
      }

      setFilterQuery({ ...filterQuery, university: university });
      fetchUniversities();
      fetchDisqualifiedStudents(1, { university: university });
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchDisqualifiedStudents(_page);
  };

  const onUniversityChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      university: value,
    });
  };

  const onCaseChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      case: value,
    });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchDisqualifiedStudents(1);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            รายชื่อผู้ถูกตัดสิทธิ์
            <div style={{ float: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setImportModalVisible(true);
                }}
              >
                นำเข้าไฟล์การตัดสิทธิ์
              </Button>{' '}
              <a
                className="example"
                href={
                  'https://tcas67.s3.ap-southeast-1.amazonaws.com/examples/%E0%B8%95%E0%B8%B1%E0%B8%94%E0%B8%AA%E0%B8%B4%E0%B8%97%E0%B8%98%E0%B8%B4%E0%B9%8C.csv'
                }
              >
                <Button type="primary" style={{ marginRight: 10 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-file"
                  >
                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                    <polyline points="13 2 13 9 20 9"></polyline>
                  </svg>{' '}
                  ไฟล์ตัวอย่าง
                </Button>
              </a>
            </div>
          </h1>

          <div className="sec-box">
            <div className="sec-form">
              <div className="col-25">
                <label>ค้นจากชื่อ, นามสกุล, รหัสประจำตัว</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>
              <div className="col-25">
                <label>
                  มหาวิทยาลัย
                  <Select
                    showSearch
                    disabled={get(me, 'role') !== 'super'}
                    style={{ width: '100%' }}
                    value={get(filterQuery, 'university')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={onUniversityChange}
                    // value={get(filterQuery, 'university')}
                    filterOption={filterLabelAndValue}
                  >
                    <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                      ทั้งหมด
                    </Option>
                    ;
                    {universities.map((university) => {
                      return (
                        <Option
                          key={university.university_id}
                          value={university.university_id}
                          label={university.university_name}
                        >
                          {university.university_name}
                        </Option>
                      );
                    })}
                  </Select>
                </label>
              </div>
              <div className="col-25">
                <label>
                  ชนิดการตัดสิทธิ์
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onCaseChange}
                      value={get(filterQuery, 'case')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value="all" label="ทั้งหมด">
                        ทั้งหมด
                      </Option>
                      {CASES.map((CASE, i) => {
                        return (
                          <Option key={i} value={CASE.value} label={CASE.label}>
                            {CASE.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchDisqualifiedStudents(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {students.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลการรับสมัคร</th>
                </tr>
              </tbody>
            </table>
          )}
          {students.length > 0 && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr>
                    <th>รหัสประจำตัวประชาชน</th>
                    <th>รหัสหลักสูตร</th>
                    {/* <th>ชื่อหลักสูตร</th> */}
                    <th>ชื่อ - นามสกุล</th>

                    <th>ชนิดการตัดสิทธิ์</th>

                    <th>เหตุผล</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((_student, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: student.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    const student = {
                      ..._student,
                      _id: null,
                      first_name: _student.first_name_th,
                      last_name: _student.last_name_th,
                    };
                    const dispalyId = (id) => (id === '0' || !id ? '-' : id);
                    const program = get(student, 'program_project.program');
                    const programProject = student.program_project || {};

                    return (
                      <tr key={i}>
                        <td>
                          {student._id ? (
                            <Link to={`/students/${student._id}`}>{student.citizen_id}</Link>
                          ) : (
                            <span>
                              {/* [ยังไม่ได้ลงทะเบียน]
                              <br /> */}
                              {student.citizen_id}
                            </span>
                          )}
                        </td>
                        <td>
                          <div>
                            <b>PRG</b>

                            <Link to={`/programs/${programProject._id}`}>{student.program_id}</Link>
                          </div>
                          <div>
                            <b>MJR</b> {dispalyId(student.major_id)}
                          </div>
                          <div>
                            <b>PRJ</b> {dispalyId(student.project_id)}
                          </div>
                        </td>
                        {/* 
                        <td>
                          {program && (
                            <>
                              <div>{program && program.program_name_th}</div>
                              <div>{program && dispalyId(program.major_name_th)}</div>
                              <div>{dispalyId(programProject.project_name_th)}</div>
                            </>
                          )}
                        </td> */}

                        <td>
                          {student.first_name} {student.last_name}
                        </td>
                        <td>
                          <div>{find(CASES, { value: student.case }).label}</div>
                        </td>

                        <td>{student.reason}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={studentCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>

      {importModalVisible && (
        <ImportModal
          setIsVisible={setImportModalVisible}
          isVisible={importModalVisible}
          onClose={() => {
            setImportModalVisible(false);
            fetchDisqualifiedStudents();
          }}
        ></ImportModal>
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
