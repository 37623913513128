import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Spin,
  Select,
  Form,
  message,
  Input,
  Radio,
  Popconfirm,
} from 'antd';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import ChangePasswordModal from './ChangePasswordModal';
import AddIpModal from './AddIpModal';

const Option = Select.Option;

moment.locale('th');

// const layout = {
//   labelCol: { span: 8 },
//   wrapperCol: { span: 16 },
// };

export default ({ selectedOrder, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [role, setRole] = useState('user');
  const [ips, setIps] = useState([]);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [addIpModalVisible, setAddIpModalVisible] = useState(false);
  const [popupConfirmIpId, setPopupConfirmIpId] = useState(false);
  const me = useSelector((state) => state.user);

  const [showSecretkey, setShowSecretkey] = useState(false);
  const universities = useSelector((state) => state.universities || []);
  const fetchIps = async () => {
    setLoading(true);

    const results = await callApi({
      url: '/users/me/ips',
    });
    setIps(results);
    setLoading(false);
  };
  useEffect(() => {
    fetchIps();
  }, []);
  const editUser = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        telephone: values.telephone,
      };

      await callApi({
        url: `/users/me`,
        method: 'put',
        body: requestBody,
      });

      setLoading(false);
      if (onClose) {
        onClose();
      }
      const me = await callApi({ url: '/users/me' });
      dispatch({ type: 'UPDATE_USER', data: me });
      message.success('แก้ไขข้อมูลผู้ใช้สำเร็จ');
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };

  const deleteIp = async () => {
    try {
      setLoading(true);

      await callApi({
        url: `/users/ips/${popupConfirmIpId}`,
        method: 'delete',
      });

      setPopupConfirmIpId(false);
      await fetchIps();
      setLoading(false);
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      message.error(get(error, 'reason.message'));

      setLoading(false);
    }
  };

  if (!me) return null;
  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>แก้ไขข้อมูลผู้ใช้</h1>

          <div className="sec-box" style={{ paddingLeft: 20, paddingBottom: 30 }}>
            <p>
              สามารถแก้ไขรหัสผ่านได้เท่านั้น หากต้องการแก้ไขอีเมล ชื่อ นามสกุล กรุณาทำหนังสือแจ้ง{' '}
              <a href="mailto:contacts@cupt.net">
                <u>contacts@cupt.net</u>
              </a>
            </p>
            <Form layout="vertical" form={form} name="control-hooks" onFinish={editUser}>
              {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}

              <div className="sec-form">
                <div className="col-25">
                  <Form.Item
                    label="อีเมล"
                    name="email"
                    initialValue={me.email}
                    rules={[{ required: true, message: 'จำเป็นต้องใส่ อีเมล' }]}
                  >
                    <Input disabled style={{ marginRight: 20 }} />
                  </Form.Item>
                </div>
                <div className="col-25">
                  <Form.Item
                    label="ชื่อ-นามสกุล"
                    name="name"
                    initialValue={me.name}
                    rules={[
                      {
                        required: true,
                        message: 'จำเป็นต้องใส่ชืือ - นามสกุล',
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </div>
                <div className="col-25">
                  <Form.Item
                    label="เบอร์โทรศัพท์"
                    name="telephone"
                    initialValue={me.telephone}
                    rules={[
                      {
                        required: true,
                        message: 'เบอร์โทรศัพท์',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>

              <div className="col-100">
                <Form.Item label="Secret key">
                  <span>{showSecretkey ? me.secretKey : '*********'}</span>{' '}
                  {!showSecretkey && (
                    <a
                      onClick={() => {
                        setShowSecretkey(true);
                      }}
                    >
                      แสดง
                    </a>
                  )}
                </Form.Item>
              </div>

              <div>
                <a onClick={() => setChangePasswordModalVisible(true)} className="">
                  แก้ไขรหัสผ่าน
                </a>
              </div>

              <div className="" style={{ marginTop: 15 }}>
                <button className="btn-main">แก้ไข</button>
              </div>
            </Form>
          </div>

          <h1>สำหรับจัดการใช้งาน API</h1>

          <div className="sec-box" style={{ paddingLeft: 20, paddingBottom: 30 }}>
            <div>
              {ips.length === 0 && <span>-- ยังไม่มี IP Address ใน Whitelist สำหรับการใช้งาน API -- </span>}
              {ips.length > 0 && (
                <>
                  <span>IP Address ที่สามารถใช้งานระบบของ myTCAS ได้ (สามารถมีได้ไม่เกิน 5 IP)</span>
                  <ul>
                    {ips.map((ip) => {
                      return (
                        <li>
                          {ip.ip}{' '}
                          {ip.user === me._id && (
                            <Popconfirm
                              title="ยืนยันการลบ"
                              visible={popupConfirmIpId === ip._id}
                              onConfirm={deleteIp}
                              onCancel={() => setPopupConfirmIpId(false)}
                              okText="ลบ"
                              cancelText="ยกเลิก"
                            >
                              <CloseCircleFilled
                                className="error pointer"
                                onClick={() => {
                                  setPopupConfirmIpId(ip._id);
                                }}
                              ></CloseCircleFilled>
                            </Popconfirm>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}

              {ips.length < 5 && (
                <div>
                  <br></br>
                  <button
                    onClick={() => setAddIpModalVisible(true)}
                    className="btn-ghost pointer"
                    style={{ cursor: 'pointer' }}
                  >
                    <PlusOutlined></PlusOutlined> เพิ่ม IP Address
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {changePasswordModalVisible && (
          <ChangePasswordModal
            setIsVisible={setChangePasswordModalVisible}
            isVisible={changePasswordModalVisible}
          ></ChangePasswordModal>
        )}

        {addIpModalVisible && (
          <AddIpModal setIsVisible={setAddIpModalVisible} isVisible={addIpModalVisible} onClose={fetchIps}></AddIpModal>
        )}
      </Spin>
    </div>
  );
};
