import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import ROUNDS from 'constants/rounds';

const { Panel } = Collapse;

import Profile from './Profile';

const OfferReply = ({ student }) => {
  // let { studentId } = useParams();
  const [replies, setReplies] = useState([]);
  const [offers, setOffers] = useState([]);

  const fetchOfferReply = async () => {
    const result = await callApi({
      url: `/students/${student.citizen_id}/offer-replies`,
    });
    setReplies(result.replies);

    // setOffers(result.offers.filter((offer) => offer.applicant_status !== '1'));
    setOffers(result.offers);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchOfferReply();
    }
  }, [student]);

  const repliesByRound = _.groupBy(replies, 'type');

  console.log(repliesByRound);

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>

      {ROUNDS.map((round) => {
        const _replies = replies.filter(({ type }) => type === round.value);
        let _offers = offers.filter(({ type }) => type === round.value);
        _offers = _offers ? _.orderBy(_offers, 'priority') : _offers;

        return (
          <div>
            <h2>รอบ {round.label}</h2>
            {_replies.length === 0 && _offers.length === 0 && <h4>--ไม่มีข้อมูลการใช้สิทธิ์--</h4>}
            {_replies.length > 0 && (
              <div>
                <Collapse>
                  <Panel header="ประวัติการใช้สิทธิ์" key="1">
                    <ul className="r-history">
                      {(_replies || []).map((reply, index) => (
                        <ReplyLog
                          key={reply._id}
                          rejectIndex={
                            _replies.length - 1 - index

                            //   _.findIndex(
                            //   replies.filter((reply) => !reply.enrollment_offer),
                            //   ({ _id }) => _id === reply._id
                            // )
                          }
                          reply={reply}
                          offers={_offers}
                        />
                      ))}
                    </ul>
                  </Panel>
                </Collapse>
              </div>
            )}

            {_offers.length > 0 && (
              <div>
                <Collapse>
                  <Panel header="ผลการรับสมัครทั้งหมด" key="1">
                    <ul className="r-history">
                      <li>
                        {_offers.map((offer) => (
                          <OfferLog key={offer._id} offer={offer} />
                        ))}
                      </li>
                    </ul>
                  </Panel>
                </Collapse>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const ReplyLog = ({ reply, rejectIndex, offers }) => {
  if (!reply.enrollment_offer) {
    if (reply.ds_priorities && reply.ds_priorities.length > 0) {
      return (
        <li>
          <h4 className="">ขอรับการประมวลผลครั้งที่ 2 (ตัดสินใจครั้งที่ {rejectIndex + 1}/3)</h4>

          <div className="u-log">
            {_.sortBy(reply.ds_priorities).map((priority) => {
              const enrollment_offer = _.find(offers, { priority: priority });

              return (
                <>
                  <h4>
                    อันดับ {priority} {_.get(enrollment_offer, `program.university_name_th`)}
                  </h4>
                  {_.get(enrollment_offer, `program.faculty_name_th`)}{' '}
                  {_.get(enrollment_offer, `program.field_name_th`)}
                  <br />
                  {_.get(enrollment_offer, `program.program_name_th`)}{' '}
                  {`(${_.get(enrollment_offer, `program.program_type_name_th`)})`}
                  <br />
                  <br />
                </>
              );
            })}
          </div>

          <time>
            {moment(reply.timestamp).format('DD MMM')} {(moment(reply.timestamp).year() + 543) % 100}{' '}
            {moment(reply.timestamp).format('HH:mm:ss')} น.
          </time>
        </li>
      );
    }

    return (
      <li>
        <h4 className="color-red">ไม่ใช้สิทธิ์ (ตัดสินใจครั้งที่ {rejectIndex + 1}/3)</h4>
        {/* <div className="u-log">
          <h4>ไม่ใช้สิทธิ์ครั้งที่ {rejectIndex + 1}</h4>
        </div> */}
        <time>
          {moment(reply.timestamp).format('DD MMM')} {(moment(reply.timestamp).year() + 543) % 100}{' '}
          {moment(reply.timestamp).format('HH:mm:ss')} น.
        </time>
      </li>
    );
  }

  return (
    <li>
      {reply.is_canceled === true ? (
        <h4 className="color-red">สละสิทธิ์ (ตัดสินใจครั้งที่ {rejectIndex + 1}/3)</h4>
      ) : (
        <h4 className="color-green">ยืนยันสิทธิ์ (ตัดสินใจครั้งที่ {rejectIndex + 1}/3)</h4>
      )}

      <div className="u-log">
        <h4>{_.get(reply, `enrollment_offer.program.university_name_th`)}</h4>
        {_.get(reply, `enrollment_offer.program.faculty_name_th`)}{' '}
        {_.get(reply, `enrollment_offer.program.field_name_th`)}
        <br />
        {_.get(reply, `enrollment_offer.program.program_name_th`)}{' '}
        {`(${_.get(reply, `enrollment_offer.program.program_type_name_th`)})`}
      </div>
      <time>
        {moment(reply.timestamp).format('DD MMM')} {(moment(reply.timestamp).year() + 543) % 100}{' '}
        {moment(reply.timestamp).format('HH:mm:ss')} น.
      </time>
    </li>
  );
};

const OfferLog = ({ offer }) => {
  let statusText;
  if (_.get(offer, `applicant_status`) === '2') {
    statusText = 'ผ่าน';
  } else if (_.get(offer, `applicant_status`) === '3') {
    statusText = 'ไม่ผ่าน';
  }
  if (_.get(offer, `applicant_status`) === '1') {
    return <div></div>;
  }

  return (
    <div className="u-log">
      <h3>{statusText}</h3>
      <h4>
        {offer.type === '3_2567' && `อันดับ ${offer.priority} `}
        {_.get(offer, `program.university_name_th`)}
      </h4>
      {_.get(offer, `program.faculty_name_th`)} {_.get(offer, `program.field_name_th`)}
      <br />
      {_.get(offer, `program.program_name_th`)} {`(${_.get(offer, `program.program_type_name_th`)})`}
    </div>
  );
};

export default OfferReply;
