module.exports = [
  {
    value: '1_2567',
    label: 'Portfolio',
  },
  {
    value: '2_2567',
    label: 'Quota',
  },
  {
    value: '3_2567',
    label: 'Admission',
  },
  {
    value: '4_2567',
    label: 'Direct Admission',
  },
  {
    value: '41_2567',
    label: 'Direct Admission 1',
  },
  {
    value: '42_2567',
    label: 'Direct Admission 2',
  },
];
